import * as React from "react";
import Preloader from "../../../../components/Preloader/Preloader";

const PresentationThumbnail = ({index, id, isActive, onThumbClick, thumbnail, backgroundColor, isLoading}) => {
  const imgUrl = thumbnail ? `url(${thumbnail})` : '';
  return (
    <div className={`presentation-thumbnail-item ${isActive ? 'is-active' : ''}`} onClick={() => onThumbClick(id)}>
      <div className={'presentation-thumbnail-index'}>{index + 1}</div>
      <div className={'presentation-thumbnail-content'}
           style={{backgroundImage: imgUrl, backgroundColor}}></div>
      <Preloader isLoading={isLoading}/>
    </div>
  );
};

export default PresentationThumbnail;
