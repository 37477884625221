import * as React from "react";
import "./Sidebar.css";
import {useWindowSize} from "../../hooks/useWindowSize";
import {BREAKPOINTS} from "../../const/breakpoints";
import {VerticalLeftOutlined} from '@ant-design/icons';

const Sidebar = ({isCollapsed, setIsCollapsed, contentWidth, children}) => {
  const [screenWidth] = useWindowSize();
  const handleButtonClick = () => {
    setIsCollapsed(!isCollapsed);
  }

  if (screenWidth >= BREAKPOINTS.LARGE) {
    return <>
      {children}
    </>
  }
  return (
    <div className={'sidebar-wrap'} style={{width: isCollapsed ? 0 : contentWidth}}>
      <div className={'sidebar-content'} style={{width: contentWidth}}>
        {children}
      </div>
      <div className={`sidebar-button ${isCollapsed ? '' : 'is-collapsed'}`} onClick={handleButtonClick}>
        <div>
          <VerticalLeftOutlined />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
