import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Select, Layout, Upload, Space, Tag, Collapse} from 'antd';
import { EnterOutlined, UploadOutlined, InboxOutlined} from '@ant-design/icons';

import "./IdeasForm.css";

const { Dragger } = Upload;
const {Option} = Select;
const { Panel } = Collapse;

function IdeasForm({ onTopicSubmit,
                       placeHolder="Type your presentation topic and press Enter",
                       errorMessage='Type a presentation topic to get started',
                       acceptedFiles=[".csv",".pdf",".txt",".doc",".docx"],
                       fileNames=null,
                       uploadedFiles=null,
                       initialSelectedFiles,
                       resetKey,
                       uploadProps=null,
                       selectedPrompt="",
                       fontDecreaseSpeed=1,
                       isInputDisabled=false,
                       openFilesCollapseDefault=false}) {
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const [fontSize, setFontSize] = useState('');
    const [files, setFiles] = useState([]);
    const [isDragActive, setIsDragActive] = useState(false);
    const [inputValue, setInputValue] = useState(selectedPrompt);
    const [fileSizeError, setFileSizeError] = useState(null);
    const [selectedFilesList , setSelectedFilesList] = useState(initialSelectedFiles);
    const [dragOver, setDragOver] = useState(false);

    const [activeKey, setActiveKey] = useState(fileNames && fileNames.length < 2 ? ["1"] : openFilesCollapseDefault ? ["1"] : []);

    const collapsePanel = () => {
        setActiveKey([]);
    };

    useEffect(() => {
        setSelectedFilesList(initialSelectedFiles);
    }, [initialSelectedFiles]);

    useEffect(() => {
        if (openFilesCollapseDefault){
            setActiveKey(["1"])
        }
    }, [fileNames, openFilesCollapseDefault]);

    useEffect(() => {
    }, [uploadedFiles]);

    useEffect(() => {
        console.log("in useEffect");
        handleChange(selectedPrompt);
    }, [selectedPrompt]);

    const handleRemove = (file) => {
        // Remove the file from the selectedFilesList state
        setSelectedFilesList((prev) =>
                prev.filter((f) => f !== file)
        );
    };

    const handleChange = (inputText) => {
        const inputLength = inputText.length;

        const newSize = inputLength ?  50 - Math.floor(fontDecreaseSpeed * inputLength / 4) : '';
        setFontSize(newSize); // adjust font size based on input length
        setInputValue(inputText); // Set the inputValue state
    };

    const onFinish = (values) => {
        console.log('onFinish values:', JSON.stringify(values));
        collapsePanel();

        if (inputValue.trim().length > 2) {
            if (files.length > 0) {
                onTopicSubmit(inputValue, files).catch((error) => {
                    if (error.response && error.response.status === 406) {
                        setError('The topic violates our terms of use');
                        form.resetFields();
                    }
                });
            }
            else{
                if (fileNames && selectedFilesList) {
                    onTopicSubmit(inputValue, selectedFilesList).catch((error) => {
                        if (error.response && error.response.status === 406) {
                            setError('The topic violates our terms of use');
                            form.resetFields();
                        }
                    })
                }
                else{
                    // Query the BE for the OpenAI API
                    // const user = firebase.auth().currentUser;
                    onTopicSubmit(inputValue).catch((error) => {
                        if (error.response && error.response.status === 406) {
                            setError('The topic violates our terms of use');
                            form.resetFields();
                        }
                    })
                }
            }
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragActive(true);
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        setIsDragActive(false);
    };

    const fileInputRef = React.createRef();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            form.submit();
        }
    };


    useEffect(() => {
        if (resetKey){
            setInputValue('');

            // also reset font size
            setFontSize('');

            // setSelectedFilesList([]);
        }
    }, [resetKey]);

    const handleFileClick = (file) => {
        if (!selectedFilesList.includes(file)) {
            setSelectedFilesList([...selectedFilesList, file]);
        } else {
            setSelectedFilesList(selectedFilesList.filter((f) => f !== file));
        }
    };

    return (
      <div className={'ideas-form-wrap'}>
          <Form
            name="topic"
            onFinish={onFinish}
            form={form}
          >
              <Form.Item style={{visibility: 'hidden', height: 0, overflow: 'hidden'}}>
                  <Button type="primary" htmlType="submit">
                      Submit
                  </Button>
              </Form.Item>
              <Form.Item
                name="topic"
                rules={[{ required: false, message: errorMessage }]}
                className={'ideas-form-item'}
                validateStatus={error ? 'error' : ''}
                help={error}
              >
                  <div>
                      <div style={{
                          display: "flex",
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'row',
                          position: 'relative',
                      }}>
                          <Input
                              name="topic"
                              value={inputValue}
                              className={'ideas-form-input'}
                              placeholder={placeHolder}
                              maxLength={120}
                              onChange={(e) => handleChange(e.target.value)}
                              style={{ fontSize: fontSize ? `${fontSize}px` : ''}}
                              onKeyPress={handleKeyPress}
                              disabled={isInputDisabled}
                          />
                          {!isInputDisabled && <Button type="primary"  icon={<EnterOutlined />} onClick={() => {form.submit()}} size="large"/>}
                      </div>

                      <div className={'ideas-form-add-content'} style={{justifyContent:"center"}}>
                          <Space wrap style={{justifyContent: "center"}}>
                              {selectedFilesList ? selectedFilesList.map((file, index) => (
                                  <Tag
                                      key={index}
                                      color={initialSelectedFiles.includes(file) ? "default" : 'blue'}
                                      closable={true}
                                      onClose={(e) => {initialSelectedFiles.includes(file) ? e.preventDefault() : handleRemove(file)}}
                                  >
                                      {file}
                                  </Tag>
                              )): ""}
                          </Space>
                    {!isInputDisabled && <Collapse
                          ghost
                          size={"small"}
                          className="customCollapse"
                          style={{justifyContent: "center", paddingLeft: "0px"}}
                          defaultActiveKey={openFilesCollapseDefault ? ['0'] : []}
                          activeKey={activeKey}
                          onChange={setActiveKey}
                      >
                          <Panel
                              header="Include files in your prompt"
                              style={{paddingLeft: "0px",
                                  border: dragOver ? "2px dashed #1890ff" : "",
                                  backgroundColor: dragOver ? "red" : "default",
                              }}
                          >
                                  <Space wrap>
                                      {fileNames && fileNames.filter(x => selectedFilesList && !selectedFilesList.includes(x)).map((file, index) => (
                                          <Tag
                                              key={index}
                                              color={uploadedFiles && uploadedFiles.includes(file) ? 'green' : 'default'}
                                              onClick={() => handleFileClick(file)}
                                          >
                                              {file}
                                          </Tag>
                                      ))}
                                  </Space>
                              <Dragger
                                  {...uploadProps}
                                  style={{
                                      display: "flex", width: "100%", height: "100%", marginTop: "10px",
                                  }}
                              >
                                  <Form.Item>
                                      <div style={{paddingTop: "10px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row"}}>
                                          <Button
                                              icon={<UploadOutlined />}
                                              // onClick={openFileDialog}
                                          >
                                          </Button>
                                          <span style={{margin: "10px"}}>{`Click or drag to upload \n (${acceptedFiles})`}</span>
                                      </div>
                                      {fileSizeError && (
                                          <div style={{ color: 'red', paddingBottom: '10px' }}>{fileSizeError}</div>
                                      )}
                                  </Form.Item>
                              </Dragger>
                          </Panel>
                      </Collapse>}
                      </div>
                  </div>
              </Form.Item>
              <div className={"line"}/>
          </Form>
      </div>
    );
}

export default IdeasForm;
