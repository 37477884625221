import * as React from "react";
import './Preloader.css';

const Preloader = ({ isLoading }) => {
  // return (
  //   <div className={`spinner ${isLoading ? 'is-loading' : ''}`}/>
  // );
  const spinnerClass = `spinner ${isLoading ? 'is-loading' : ''}`;
  return (
      <div className={spinnerClass}>
        {[...Array(8)].map((_, index) => (
            <div key={index} />
        ))}
      </div>
  );
};

export default Preloader;
