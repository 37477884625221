import axios from "axios";
import { FASTAPI_URL } from "../const/api";
import firebase from 'firebase';

const api = axios.create({
  withCredentials: true,
});


export async function getChart(prompt, files, sessionToken) {
    let token = await firebase.auth().currentUser.getIdToken();

    const suffix = sessionToken  ? `?session=${sessionToken}` : "";
    const apiUrl = `${FASTAPI_URL}/chart/${suffix}`;

    let dataToSend = {
        csv_files: files,
        prompt: prompt,
    };
    if (sessionToken) {
        dataToSend.sessionToken = sessionToken;
    }
    let headers = {
        'Authorization': `Bearer ${token}`
    };

    // if (files && files.length > 0) {
    //     dataToSend = new FormData();
    //     files.forEach((file) => {
    //         dataToSend.append('files', file);
    //     });
    //     headers['Content-Type'] = 'multipart/form-data';
    // }

    const response = await axios.post(apiUrl, dataToSend, {
        headers: headers
    });
    return response.data
};


export async function getFullResponse(sessionId, queryId) {
    const token = await firebase.auth().currentUser.getIdToken();
    const apiUrl = `${FASTAPI_URL}/chart/response/${sessionId}/${queryId}`;
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const response = await axios.get(apiUrl, { headers });
    return response.data
}

export async function getFullCSV(sessionId, queryId) {
    const token = await firebase.auth().currentUser.getIdToken();
    const apiUrl = `${FASTAPI_URL}/chart/csv/${sessionId}/${queryId}`;
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const response = await axios.get(apiUrl, { headers, responseType: 'blob' });
    return response.data;
}