import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {fabric} from "fabric";
import {CANVAS_BASE_WIDTH, CANVAS_H_TO_W_RATIO, CANVAS_JSON_PARAMS} from "../const/canvas";

function useCanvas(elementId, wrapperRef, isFullscreen) {
  const canvasRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [canvasState, setCanvasState] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(0);
  useEffect(() => {
    initCanvas();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    canvasRef.current.discardActiveObject().renderAll();
  }, [isFullscreen]);

  const initCanvas = () => {
    if (canvasRef.current) {
      return;
    }
    canvasRef.current = new fabric.Canvas(elementId, { preserveObjectStacking: true });
    setListeners();
    setIsReady(true);
    handleResize();
  }

  const setListeners = () => {
    canvasRef.current.on("object:modified", updateData);
    canvasRef.current.on("object:added", updateData);
    canvasRef.current.on("object:removed", updateData);
  }

  const updateData = () => {
    updateThumbnail();
    updateCanvasState();
  }

  const updateThumbnail = () => {
    setThumbnail(canvasRef.current.toDataURL({
      format: 'png',
      quality: 1
    }));
  }

  const updateCanvasState = () => {
    setCanvasState(canvasRef.current.toJSON(CANVAS_JSON_PARAMS));
  }

  const handleResize = () => {
    if (!canvasRef.current || !wrapperRef.current) {
      return;
    }
    const {width, height} = wrapperRef.current.getBoundingClientRect();
    canvasRef.current.setDimensions({width, height: width * CANVAS_H_TO_W_RATIO});
    canvasRef.current.setZoom(width / CANVAS_BASE_WIDTH);
    setCanvasWidth(width);
  }

  return {canvas: canvasRef.current, isReady, thumbnail, canvasState, updateData, canvasWidth};


}

export default useCanvas;
