import axios from 'axios';
import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Card, Col, Collapse, Layout, Menu, message, Row, Space, Spin, Tag, Tooltip, Typography} from 'antd';
import {
    CheckCircleOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
    FrownOutlined,
    LoadingOutlined,
    SmileOutlined,
    StockOutlined
} from '@ant-design/icons';
import {COLORS} from "../../const/styles";
import './Charts.css';
import Preloader from "../../components/Preloader/Preloader";
import IdeasForm from "../ideas/components/IdeasForm/IdeasForm";
import {getChart, getFullCSV, getFullResponse} from "../../requests/charts";
import useLogin from "../../hooks/useLogin";
import firebase from 'firebase';
import {FASTAPI_URL} from "../../const/api";
import {ROUTE as LOGIN_ROUTE} from '../login';
import {getUserFiles} from "../../requests/analysis";
import {useParams} from 'react-router-dom';
import PricingModal from "../presentation/components/PricingModal/PricingModal";
import { useNavigate, useLocation } from 'react-router-dom';
// import {ImageWithDownload} from "./ImageWithDownload";
import ReactGA from "react-ga4";
import {checkUserSubscription} from "../../requests/slides";
import {saveAs} from 'file-saver';
import {REDIRECT_URI_PARAM} from "../login/const";
import Sidebar from "../../components/Sidebar/Sidebar";

const { Paragraph } = Typography;

const {Sider, Content} = Layout;
const {Panel} = Collapse;

const Charts = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [lastResponse, setLastResponse] = useState(null);
    const [chartHistory, setChartHistory] = useState(null);
    const [userFiles, setUserFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [prompt, setPrompt] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedKey, setSelectedKey] = useState('');
    const [token, setToken] = React.useState(null);
    const [imageLoaded, setImageLoaded] = useState({});
    const [resetKey, setResetKey] = useState(0);
    const [showSuggestedPrompts, setShowSuggestedPrompts] = useState(true);
    const [selectedPrompt, setSelectedPrompt] = useState('');
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [isUserSubscribed, setIsUserSubscribed] = useState(false);
    const [fullResponse, setFullResponse] = useState(null);
    const [seeMore, setSeeMore] = useState(false);
    const { user } = useLogin();
    const navigate = useNavigate();
    const { sessionId } = useParams();
    const location = useLocation();

    const tagOptions = [
        { label: 'Show me the average temperature in New York City for the past 10 years', color: 'magenta' },
        { label: 'Compare the GDP of the United States and China from 2000 to 2020', color: 'red' },
        { label: 'Display the population growth of India from 1990 to 2020', color: 'volcano' },
        { label: 'Visualize the unemployment rate in the European Union for the past 5 years', color: 'orange' },
        { label: 'Compare the number of internet users in North America and South America from 1995 to 2021', color: 'gold' },
        { label: 'Show the distribution of renewable energy sources worldwide', color: 'lime' },
        { label: 'Illustrate the global carbon dioxide emissions by sector from 1990 to 2020', color: 'green' },
        { label: 'Compare the life expectancy at birth for males and females in the United States from 1950 to 2020', color: 'cyan' },
        { label: 'Visualize the global smartphone market share from 2010 to 2021', color: 'blue' },
        { label: 'Display the top 10 countries with the highest number of COVID-19 cases', color: 'geekblue' },
        { color: "magenta", label: "Pie chart: popular programming languages in 2022" },
        { color: "red", label: "Line chart: global CO2 emissions from 1990 to 2020" },
        { color: "volcano", label: "Bar chart: top 10 highest-grossing movies of all time" },
        { color: "orange", label: "Scatter plot: GDP per capita vs life expectancy" },
        { color: "gold", label: "Stacked bar chart: renewable energy production by country in the last 5 years" },
        { color: "lime", label: "Radar chart: nutritional values of different fruits" },
        { color: "green", label: "Chart: average annual temperature changes in major cities (30 years)" },
        { color: "cyan", label: "Chart: growth of internet users worldwide (2000-2020)" },
        { color: "blue", label: "Bubble chart: population, GDP, and land area for different countries" },
        { color: "geekblue", label: "Histogram: age distribution among users of a popular social media platform" },
        { color: "purple", label: "Bar chart: monthly sales data from 'sales_data.csv' in the last year" },
        { color: "magenta", label: "Line chart: stock prices of major tech companies from 'tech_stocks.csv' in the past 5 years" },
        { color: "red", label: "Pie chart: distribution of product categories in 'products.csv'" },
        { color: "volcano", label: "Scatter plot: relationship between price and quality from 'product_ratings.csv'" },
        { color: "orange", label: "Area chart: growth of users for different features in 'user_growth.csv' over the past 3 years" },
    ];
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    const [randomTags , setTandomTags] = useState(shuffleArray(tagOptions).slice(0, 5));

    const handleCloseModal = () => {
        ReactGA.event({
            category: 'User Interaction',
            action: 'User closed Pricing Modal',
        });
        setShowPricingModal(false);
    };

    React.useEffect(() => {
        const fetchToken = async () => {
            const currentUser = firebase.auth().currentUser;
            if (currentUser) {
                const token = await currentUser.getIdToken();
                setToken(token);
            }
        };
        fetchToken();
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            const data = await getUserFiles();
            const filteredData = data.filter(file => {
                const extension = file.split('.').pop().toLowerCase();
                return extension === 'csv' || extension === 'txt';
            });
            setUserFiles(filteredData);
        };
        if (user) {
            fetchData();
        }
    }, [user]);

    const isSubscribed = async () => {
        try {
            const response = await checkUserSubscription();
            return response.data.is_subscribed
        } catch (error) {
            return false
        }
    };

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            const status = await isSubscribed();
            setIsUserSubscribed(status);
        };

        fetchSubscriptionStatus();
    }, []);

    const handleSuggestedPromptClick = (prompt) => {
        // Paste the selected prompt into the search box
        console.log(prompt);
        setSelectedPrompt(prompt);
    };

    const handlePromptSubmit = async (_prompt, selectedFiles) => {
        setIsLoading(true);
        setPrompt(_prompt);
        try {
            const data = await getChart(_prompt, selectedFiles, selectedSession?.id);
            // console.log(`Response: ${data}`);
            setLastResponse(data);

            // Hide the suggested prompts
            setShowSuggestedPrompts(false);

        } catch (error) {
            // user must subscribe
            if (error.response && error.response.status === 402) {
                ReactGA.event({
                    category: 'Charts',
                    action: 'Reached limit',
                });
                message.info("You have reached your charts limit. Please subscribe");
                setShowPricingModal(true);
            } else {
                console.log(error);
                message.error("Unknown Error. Please try again");
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!user && !sessionId) {
            return;
        }
        let isFirstLoad = true;
        console.log(firebase.firestore.FieldPath.documentId());
        // Subscribe to the real-time updates from Firebase
        let unsubscribe = firebase.firestore().collection("chart_queries");
        if (sessionId) {
            unsubscribe = unsubscribe.where(firebase.firestore.FieldPath.documentId(), '==', sessionId)
        }
        else if (user) {
            unsubscribe = unsubscribe.where("user", "==", user.uid).orderBy("timestamp", "desc");
        }

        unsubscribe = unsubscribe
            .onSnapshot((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => {
                    console.log(doc.data());
                    return {
                        id: doc.id,
                        queries: doc.data().queries,
                        cell_results: doc.data().cell_results,
                        generated_image: doc.data().generated_image,
                        used_hard_coded_data: doc.data().used_hard_coded_data,
                        worked: doc.data().worked,
                        timestamp: doc.data().timestamp,
                        files: doc.data().files,
                        status: doc.data().status,
                        source: doc.data().source,
                        source_date_updated: doc.data().source_date_updated,
                    };
                });
                console.log("in update - selectedKey", selectedKey, "selectedSession?.id", selectedSession?.id);
                setChartHistory(data);
                if (isFirstLoad && !sessionId) {
                    isFirstLoad = false;
                }
                else {
                    // firebase update (we either have a selectedSession, or it's a new session
                    // if it's a selected session we already have it in selectedSession
                    // if it's  a new one , we just set the selectedSession directly.
                    if (selectedSession?.id) {
                        const selectedSessionIndex = data.findIndex((session) => session.id === selectedSession.id);
                        console.log("selectedSessionIndex:", selectedSessionIndex);
                        if (selectedSessionIndex !== -1) {
                            applySelectedSession(data[selectedSessionIndex]);
                            setSelectedKey((selectedSessionIndex - 1).toString());
                        }
                    }else if (data[0]?.id){
                        const selectedSessionIndex = data[0];
                        applySelectedSession(data[0]);
                        console.log("in if - selectedSession", selectedSessionIndex);
                        if (!selectedKey){
                            setSelectedKey(selectedSessionIndex.toString());
                        }
                    }
                }
                // this sets the right menu key.
                // if it's a new session (selectedKey is null), set it to the first menu item
                if (!selectedKey) {
                    setSelectedKey('1');
                }
                console.log(selectedSession);
            }, (error) => {
                console.log("Error getting documents: ", error);
            });

        // Clean up the subscription when the component is unmounted
        return () => {
            unsubscribe();
        };
    }, [user]);

    const applySelectedSession = (selectedSession) => {
        // reset the search query (idea form)
        setResetKey((prevKey) => prevKey + 1);
        setImageLoaded({});
        setShowSuggestedPrompts(false);
        setSelectedSession(selectedSession);
        setFullResponse(null);
        setSeeMore(false);
        try{
            setSelectedFiles(
                selectedSession &&
                selectedSession.queries &&
                selectedSession.queries[selectedSession.queries.length - 1].files.length
                    ? selectedSession.queries[selectedSession.queries.length-1].files
                    : []
            );
        } catch (error){
            console.log("error", error)
        }
    };

    const updateUserFiles = (uploadedFile, status, percent=100) => {
        const updatedFile = {
            name: uploadedFile,
            status: status,
            percent: percent,
        };

        if (status === "done"){
            setUserFiles((prevState) => [...prevState, updatedFile.name]);
            setUploadedFiles((prevState) => [...prevState, updatedFile.name]);
        }
    };

    const customRequest = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                url: `${FASTAPI_URL}/user/upload/`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    updateUserFiles(file.name, 'uploading', percentCompleted);
                    onProgress({ percent: percentCompleted }, file);
                },
            });

            if (response.status === 200) {
                onSuccess(response.data, file);
                message.success(`${file.name} file uploaded successfully`);
                updateUserFiles(file.name, 'done');
            } else {
                onError(new Error('Upload failed.'));
                message.error(`${file.name} file upload failed.`);
                // updateUserFiles(file.name, 'error');
            }
        } catch (error) {
            if (error.response && error.response.status === 402) {
                message.info("You have reached your files limit. Please subscribe");
                ReactGA.event({
                    category: 'Charts',
                    action: 'Reached files limit',
                });
                setShowPricingModal(true);
                // updateUserFiles(file.name, 'error');
            } else{
                onError(error);
                message.error(`${file.name} file upload failed.`);
                // updateUserFiles(file.name, 'error');
            }
        }
    };

    const uploadProps = {
        name: "file",
        openFileDialogOnClick: true,
        action: `${FASTAPI_URL}/user/upload/`,
        multiple: true,
        accept: ".csv",
        showUploadList: false,
        // showUploadList: {
        //     showRemoveIcon: true, // Hide remove icon in the file list
        // },
        fileList: selectedFiles,
        headers:{
            Authorization: `Bearer ${token}`,
        },
        customRequest: customRequest,
    };

    const handleImageLoad = (index) => {
        setImageLoaded((prevState) => ({ ...prevState, [index]: true }));
    };

    const handleSessionClick = (session) => {
        console.log('Session clicked:', session);
        applySelectedSession(session);
        // console.log("selectedFiles after", selectedFiles);
    };

    const deleteSession = (sessionId) => {
        // try {
        //     // Assuming you have a method in your backend to handle deleting the session
        //     await deleteSessionFirebase("charts", session.id);
        //     // Then remove it from chartHistory
        //     setChartHistory(chartHistory.filter(s => s.id !== session.id));
        // } catch (error) {
        //     console.log('Failed to delete session:', error);
        // }
    };

    const getChartSearchCount = (id) => {
        console.log(id);
    };

    const handleLikeClick = (id) => {
        console.log(id);
    };

    const handleUnlikeClick = (id) => {
        console.log(id);
    };

    const handleShareClick = (id) => {
        console.log(id);
    };

    const handlePanelChange = async (key, sessionId, queryId) => {
        if (key === "2" && !fullResponse && isUserSubscribed && !seeMore) {
            const response = await getFullResponse(sessionId, queryId);
            setFullResponse(response?.content);
            setSeeMore(true);
        }
        else if((key === "2" && seeMore)){
            setSeeMore(false);
        }
    };

    const handleSeeMoreClick = (sessionId, queryId) => {
        if (isUserSubscribed) {
            handlePanelChange("2", sessionId, queryId);
        } else {
            setShowPricingModal(true);
        }
    };


    async function handleDownload(sessionId, queryId) {
        const data = await getFullCSV(sessionId, queryId);
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'data.csv');
    }

    const handleNotLoggedIn = () => {
        if (!user) {
            navigate(`${LOGIN_ROUTE}?${REDIRECT_URI_PARAM}=${encodeURIComponent(location.pathname)}`);
            return true;
        }
        return false;
    }

    const renderContent = () => {
        return(
            <Layout style={{ minHeight: '100vh', backgroundColor: COLORS.bgMain}}>
                <Row>
                    <Sidebar contentWidth={260} isCollapsed={collapsed} setIsCollapsed={setCollapsed}>
                        <Col
                          className={'page-column'}
                          xs={24}
                          lg={6}
                          theme={"light"}
                          style={{backgroundColor: COLORS.bgMain, borderRight: "2px"}}
                        >
                            <div
                              style={{
                                  height: 32,
                                  margin: 16,
                              }}
                              onClick={() => {
                                  // set null as the current session
                                  applySelectedSession(null);

                                  // remove the menu highlight
                                  setSelectedKey('');

                                  // Show the suggested prompts
                                  setShowSuggestedPrompts(true);
                              }}
                            >
                                {/*<Button type="default" icon={<StockOutlined />} style={{width: "calc(100% - 16px)"}}>New session</Button>*/}
                            </div>
                            <Menu
                              style={{backgroundColor: COLORS.bgMain}}
                              selectedKeys={selectedKey !== '' ? [selectedKey] : []}
                              theme={"light"}>
                                {chartHistory && chartHistory
                                  .map((session, index) => (
                                    <Menu.Item key={index + 1} onClick={() => {
                                        handleSessionClick(session);
                                        setSelectedKey((index + 1).toString());
                                        setCollapsed(true);
                                    }}>
                                        {session?.queries[session.queries.length-1]?.worked
                                          ? <SmileOutlined style={{color: "green"}}/>
                                          : session?.queries[session.queries.length-1]?.worked === false || (Date.now() - session.timestamp.toMillis() >= 360000)
                                            ? <FrownOutlined />
                                            : <LoadingOutlined/>}
                                        {` ${session.prompt || session?.queries[session.queries.length-1]?.query?.content}`}
                                        <CloseOutlined onClick={(e) => { e.stopPropagation(); deleteSession(session.id); }} />
                                    </Menu.Item>
                                  ))}
                            </Menu>
                        </Col>
                    </Sidebar>
                    <Col className={'page-column'} xs={24} lg={18}>
                        <Layout style={{ backgroundColor: COLORS.bgMain}}>
                            <Content className={'page-content'}>
                                <div>
                                    {Boolean(user) && <>
                                        <IdeasForm
                                          selectedPrompt={selectedPrompt}
                                          onTopicSubmit={handlePromptSubmit}
                                          placeHolder={'Describe your chart and press Enter'}
                                          errorMessage={'Fill in with something to continue...'}
                                          acceptedFiles={['.csv']}
                                          fileNames={userFiles.concat(uploadedFiles)}
                                          uploadedfFiles={uploadedFiles}
                                          initialSelectedFiles={selectedFiles}
                                          uploadProps={uploadProps}
                                          resetKey={resetKey}
                                          fontDecreaseSpeed={1.5}
                                        />
                                        <Spin indicator={antIcon} spinning={isLoading} style={{width: "100%", padding: "10px"}}/>
                                    </>}

                                    {selectedSession && selectedSession.status !== "done" && selectedSession.timestamp &&
                                      (Date.now() - selectedSession.timestamp.toMillis() <= 360000) && <div
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                          <div> {selectedSession.status}</div>
                                          <Spin indicator={antIcon} spinning={true} style={{padding: "10px"}} size={"small"}/>
                                      </div>}

                                    {Boolean(user) && showSuggestedPrompts && (
                                      <div style={{justifyContent: "center", marginTop: "30px",
                                          display:"flex",
                                          flexDirection: "column",
                                          flexWrap: "wrap"
                                      }}>
                                          <p style={{marginTop: "5px", marginBottom: '5px' }}>Need an idea? Try one of these prompts:</p>
                                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                                              {randomTags.map((tag, index) => (
                                                <Tag
                                                  style={{ marginTop: "5px", cursor: "pointer", marginRight: "5px"}}
                                                  key={index}
                                                  color={tag.color}
                                                  onClick={() => handleSuggestedPromptClick(tag.label)}
                                                >
                                                    {tag.label}
                                                </Tag>
                                              ))}
                                          </div>
                                      </div>
                                    )}
                                    {selectedSession && selectedSession.queries &&
                                      selectedSession.queries.map((x, index) => (
                                        <Card key={`${selectedSession.id}-${index}`} style={{whiteSpace: "pre-wrap", textAlign: "left", marginBottom:"5px"}}>
                                            <Col key={`${selectedSession.id}-${index}`} justify="center" align="middle">
                                                <Row>{<span style={{fontWeight: "bold"}}>Prompt</span>}: {x?.query?.content}</Row>
                                                <Row style={{ justifyContent: 'center' }}>
                                                    <div key={`image-container-${selectedSession.id}-${index}`}>
                                                        {x.generated_image ? (
                                                          <>
                                                              {!imageLoaded[index] && <Spin />}
                                                              {/*<ImageWithDownload*/}
                                                              {/*    key={index}*/}
                                                              {/*    imageUrl={`${FASTAPI_URL}/assets/${x.generated_image}`}*/}
                                                              {/*    downloadUrl={`${FASTAPI_URL}/assets/${x.generated_image}`}*/}
                                                              {/*    altText={x?.query?.content}*/}
                                                              {/*    onLoad={() => handleImageLoad(index)}*/}
                                                              {/*    imageLoaded={imageLoaded[index]}*/}
                                                              {/*/>*/}
                                                              <MemoizedImage
                                                                alt={x?.query?.content}
                                                                src={`${FASTAPI_URL}/assets/${x.generated_image}`}
                                                                onLoad={() => handleImageLoad(index)}
                                                                style={{ display: imageLoaded[index] ? "block" : "none", maxHeight:"100%", maxWidth:"100%"}}
                                                              />
                                                              {(!user || !isUserSubscribed) && imageLoaded[index] ? (
                                                                  <div style={{ textAlign: "right"}}>
                                                                      Remove the watermark? <Tag onClick={() => {
                                                                      if (handleNotLoggedIn()) return;

                                                                      setShowPricingModal(true);
                                                                  }} style={{cursor: "pointer"}}> Subscribe </Tag>
                                                                  </div>
                                                              ) : null}
                                                          </>
                                                        ) : (
                                                          ''
                                                        )}
                                                    </div>
                                                </Row>
                                                <Collapse
                                                  ghost
                                                  defaultActiveKey={x.cell_results && x.cell_results.length ? ["1"]: x?.response?.content.length>0 ? ["2"] : null}
                                                  // onChange={(key) => handlePanelChange(key, selectedSession.id, index)}
                                                >
                                                    {
                                                        x.cell_results && x.cell_results.length
                                                          ?
                                                          <Panel header="Results" key="1" style={{textAlign: "left", whiteSpace: "pre-wrap"}}>
                                                              <Row style={{textAlign: "left"}}>${x.cell_results[0]}</Row>
                                                          </Panel>
                                                          : ''
                                                    }

                                                    {isUserSubscribed  && <Panel header="Code" key="2" style={{textAlign: "left", paddingBottom: "10px"}}>
                                                        <Paragraph>
                                                    <pre>
                                                        <code>
                                                            {fullResponse || x?.response?.content
                                                              ? <Row style={{textAlign: "left"}}>{`${fullResponse || x?.response?.content || x.content}`}</Row>
                                                              : <Row>{selectedSession.status}</Row>
                                                            }
                                                        </code>
                                                    </pre>
                                                        </Paragraph>
                                                        <Button onClick={() => handleSeeMoreClick(selectedSession.id, index)}>{seeMore ? 'See Less' : 'See full code'}</Button>
                                                    </Panel>}

                                                </Collapse>


                                                <Row style={{justifyContent: "space-between", alignItems: "center"}}>
                                                    {x.used_hard_coded_data &&
                                                      <Tooltip placement="right" title={"It was not possible to produce outcomes using actual data. Try providing your own data"}>
                                                          <Tag icon={<ExclamationCircleOutlined />} color={"yellow"}> {`Hard coded`}</Tag>
                                                      </Tooltip>}
                                                    {x.source &&
                                                      <div>
                                                          <Tooltip placement="right" title={"Source"}>
                                                              <Tag icon={<CheckCircleOutlined/>} color={"success"}> {x.source}</Tag>
                                                          </Tooltip>
                                                          <Tooltip placement="right" title={"Date updated"}>
                                                              <Tag color={"cyan"}> {x.source_date_updated}</Tag>
                                                          </Tooltip>
                                                      </div>}
                                                </Row>
                                                <Row>
                                                    {x.source &&
                                                    <div style={{alignSelf: "flex-start", paddingTop: "10px"}}>
                                                        {isUserSubscribed ? (
                                                            <Button onClick={() => handleDownload(selectedSession.id, index)}>Download data</Button>
                                                        ) : (
                                                            <Button onClick={() => {
                                                                ReactGA.event({
                                                                    category: 'User Interaction',
                                                                    action: 'Download (Chart data)',
                                                                });

                                                                // If user is not logged in, redirect to login page
                                                                if (handleNotLoggedIn()) return;

                                                                setShowPricingModal(true)
                                                            }}>Download data</Button>
                                                        )}
                                                    </div>}
                                                </Row>
                                            </Col>
                                            <Row style={{ justifyContent: 'flex-end', alignItems: 'center'}}>
                                                {/*<Tooltip placement="right" title="Like">*/}
                                                {/*    <DislikeOutlined onClick={() => handleLikeClick(x)}  style={{ fontSize: '24px', marginRight: '10px'}}/>*/}
                                                {/*</Tooltip>*/}
                                                {/*<Tooltip placement="right" title="Unlike">*/}
                                                {/*    <LikeOutlined onClick={() => handleUnlikeClick(x)}  style={{ fontSize: '24px', marginRight: '10px'}}/>*/}
                                                {/*</Tooltip>*/}
                                                {/*<Tooltip placement="right" title="Share">*/}
                                                {/*    <ShareAltOutlined onClick={() => handleShareClick(x)}  style={{ fontSize: '24px' }} />*/}
                                                {/*</Tooltip>*/}
                                            </Row>
                                        </Card>
                                      ))}
                                </div>
                                <PricingModal isShown={showPricingModal} onCancel={handleCloseModal}/>
                            </Content>
                        </Layout>
                    </Col>
                </Row>

            </Layout>
        )
    };

    return (
        <>
            {renderContent()}
            <Preloader isLoading={isLoading}/>
        </>
    );
};

const MemoizedImage = ({ src, alt, onLoad, style }) => {
    return (
      <img
        alt={alt}
        src={src}
        onLoad={onLoad}
        style={style}
      />
    );
};

export default Charts;
