import * as React from "react";
import {useEffect, useRef} from "react";
import {fabric} from "fabric";
import './ImageCrop.css';

const CANVAS_ID = 'image-crop-canvas';
const DEFAULT_CANVAS_SIZE = 1024;

const ImageCropItem = ({imageOriginData, imageHeightToWidthRatio, onImageCrop}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    initCanvas();
  }, []);

  useEffect(handleImageCropRequest, [imageOriginData]);

  const initCanvas = () => {
    if (canvasRef.current) {
      return;
    }
    canvasRef.current = new fabric.Canvas(CANVAS_ID, {preserveObjectStacking: true});
  }

  function handleImageCropRequest() {
    if(!imageOriginData?.baseUrl) {
      return;
    }
    const originImageRatio = imageOriginData.height / imageOriginData.width;
    canvasRef.current.clear();
    fabric.Image.fromURL(imageOriginData?.baseUrl, function (oImg) {
      if (imageHeightToWidthRatio >= originImageRatio) {
        canvasRef.current.setDimensions({
          width: DEFAULT_CANVAS_SIZE / imageHeightToWidthRatio,
          height: DEFAULT_CANVAS_SIZE
        });
        oImg.scaleToHeight(DEFAULT_CANVAS_SIZE);

      } else {
        canvasRef.current.setDimensions({
          width: DEFAULT_CANVAS_SIZE,
          height: DEFAULT_CANVAS_SIZE * imageHeightToWidthRatio
        });
        oImg.scaleToWidth(DEFAULT_CANVAS_SIZE);

      }
      canvasRef.current.add(oImg);
      oImg.center();
      canvasRef.current.renderAll();
      onImageCrop(canvasRef.current.toDataURL({
        format: 'png',
        quality: 1
      }));
    }, {
      crossOrigin: 'anonymous'
    });
  }

  return (
    <div className={'image-crop-wrap'}>
      <div>
        <canvas id={CANVAS_ID} width={DEFAULT_CANVAS_SIZE} height={DEFAULT_CANVAS_SIZE}></canvas>
      </div>
    </div>
  );
};

export default ImageCropItem;
