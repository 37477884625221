import * as React from "react";
import {Button as AntdButton, Tooltip} from 'antd';
import "./Button.css";

const Button = ({text, iconSrc, icon, isDisabled, onClick, htmlType, children, tooltip}) => {
  const buttonContent = <AntdButton
    className={'button-custom'}
    htmlType={htmlType}
    disabled={isDisabled}
    onClick={onClick}
    icon={icon}
  >
    {Boolean(iconSrc) && <img className={'button-icon'} src={iconSrc} alt="icon"/>}
    {Boolean(text) && <span className={'button-text'}>{text}</span>}
    {children}
  </AntdButton>
  if (tooltip) {
    return <Tooltip placement={'bottom'} title={tooltip} arrow={false} color={'#6172F3'}>
      {buttonContent}
    </Tooltip>
  }
  return buttonContent;
};

export default Button;
