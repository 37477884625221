import {fabric} from "fabric";
import {CANVAS_BASE_HEIGHT, CANVAS_BASE_WIDTH} from "../const/canvas";
import slideColors from "../configs/slideColors.json";
import {v4 as uuidv4} from "uuid";
import slideTemplates from "../configs/slideTemplates.json";

const baseSlideTemplates = slideTemplates.baseSlide;

export function addImageToSlide(canvas, imageId, croppedImageURL, templateParams, photoCredits, onComplete) {
  const replacedImage = canvas.getObjects().find(_item => {
    return _item.id === imageId;
  });
  if (replacedImage) {
    replaceImage(canvas, replacedImage, imageId, croppedImageURL, templateParams, photoCredits, onComplete);
    return;
  }
  const {left, top, width, height} = templateParams;

  fabric.Image.fromURL(croppedImageURL, function (oImg) {
    oImg.set({
      left: CANVAS_BASE_WIDTH * (left / 100),
      top: CANVAS_BASE_HEIGHT * (top / 100),
      photoCredits: photoCredits
    });
    oImg.set({opacity: 1});
    oImg.scaleToWidth(CANVAS_BASE_WIDTH * (width / 100));
    canvas.add(oImg);
    oImg.sendToBack();
    canvas.renderAll();
    onComplete(replacedImage);
  }, {
    id: imageId,
    crossOrigin: 'anonymous'
  });
}

export function replaceImage(canvas, renderedImage, imageId, croppedImageURL, templateParams, photoCredits, onComplete) {
  const {left, top, height, width, scaleX, scaleY} = renderedImage;

  const imageExists = canvas.getObjects().find(_item => {
    return _item === renderedImage;
  });
  if (!imageExists) {
    return;
  }
  const zindex = canvas.getObjects().indexOf(renderedImage);
  canvas.remove(renderedImage);
  canvas.discardActiveObject().renderAll();
  fabric.Image.fromURL(croppedImageURL, function (oImg) {
    oImg.set({
      left,
      top,
      scaleX,
      scaleY,
      photoCredits
    })
    oImg.set({opacity: 0});
    canvas.add(oImg);
    // addPhotoLabel(photoCredits, oImg, canvas);
    oImg.moveTo(zindex);
    oImg.animate('opacity', '1', {
      duration: 1000,
      onChange: canvas.renderAll.bind(canvas),
      onComplete: function () {
        canvas.setActiveObject(oImg).renderAll();
        onComplete();
      }
    });
  }, {
    id: imageId,
    crossOrigin: 'anonymous'
  });
}

export function addPhotoLabel(photoCredits, oImg, canvas) {
  const {username} = photoCredits ? photoCredits : {username: null};
  if (username && username.length) {
    const fontSize = 8;
    const padding = 4;
    const labelHeight = fontSize + padding * 2;
    const label = new fabric.Text(`Photo by ${username} from Pexels`, {
      id: 'label',
      left: oImg.left + padding,
      top: oImg.top + labelHeight - padding,
      fontSize,
      fill: '#252525',
      fontFamily: 'Arial',
      backgroundColor: '#b4b4b4',
      opacity: 0.8,
      textAlign: 'center',
      visible: true,
    });
    canvas.add(label);
  }
}

export function generateCreditsSlideContent(slides) {
  const credits = {};

  slides.forEach((slide) => {
    if (slide.imageData) {
      slide.imageData.forEach((imageDataObj) => {
        if (imageDataObj.photoCredits) {
          if (credits[slide.topic]) {
            credits[slide.topic].push(imageDataObj.photoCredits);
          } else {
            credits[slide.topic] = [imageDataObj.photoCredits];
          }
        }
      });
    }
  });
  return Object.entries(credits).flatMap(([slideTopic, photoCredits]) => {
    return [
      ...photoCredits.map((credit) => `Photo by ${credit.username} ${credit.url}`)
    ];
  });
}

export function createCreditsSlide(slides) {
  const creditsContent = generateCreditsSlideContent(slides);

  const colorScheme = slideColors[Math.floor(Math.random() * slideColors.length)];
  const baseSlideTemplateIndex = Math.floor(Math.random() * baseSlideTemplates.length);
  const template = baseSlideTemplates[baseSlideTemplateIndex];
  return {
    id: uuidv4(),
    topic: "Credits",
    slideText: creditsContent,
    imageData: null,
    thumbnail: '',
    state: null,
    renderStatus: null,
    ...colorScheme,
    headerTemplateIndex: null,
    baseSlideTemplateIndex,
    template,
    isCreditSlide: true
  };
}
