import * as React from "react";
import {fabric} from 'fabric';
import {useEffect, useRef, useState} from "react";
import {FullScreen} from "react-full-screen";
import useCanvas from "../../../../hooks/useCanvas";
import Preloader from "../../../../components/Preloader/Preloader";
import PresentationTypographyControls from "./PresentationTypographyControls";
import {v4 as uuidv4} from 'uuid';
import ImageCrop from "../ImageCrop/ImageCrop";
import CanvasElementControls from "../CanvasElementControls/CanvasElementControls";
import {designSlides, fetchImage} from "../../../../requests/slides";
import {convertTextToList} from "../../../ideas/utils/presentation";
import {addPhotoLabel, replaceImage} from "../../../../utils/slide";
import {FASTAPI_URL} from "../../../../const/api";
import useLogin from "../../../../hooks/useLogin";
import {SLIDE_STATUSES} from "../../../../const/slide";
import {usePresentationState} from "../../state/presentation";

const PresentationSlideContent = ({
                                    presentationTopic,
                                    id,
                                    fullscreenHandle,
                                    onFullscreenSlideClick,
                                    isFullscreen,
                                    readOnly,
                                    onCanvasClick,
                                    sessionToken
                                  }) => {
  const wrapRef = useRef(null);
  const {getSlideData, updateSlide, updateCounter} = usePresentationState();
  const {state: slideJSON, color: textColor, topic: slideTopic, renderStatus, isRendered} = getSlideData(id);
  const {
    canvas,
    isReady,
    thumbnail,
    canvasState,
    updateData,
    canvasWidth
  } = useCanvas('canvas-main', wrapRef, isFullscreen);

  const [isLoadingSlide, setIsLoadingSlide] = useState(false);
  const isLoading = (renderStatus === SLIDE_STATUSES.CONTENT_LOADING) || isLoadingSlide;
  const [regenerateInProgress, setRegenerateInProgress] = useState(false);
  const [hoveredObject, setHoveredObject] = useState(false);
  const {user} = useLogin();

  // useEffect(() => {
  //   renderImageLabel();
  // }, [canvas, hoveredObject]);

  // useEffect(() => {
  //   if (canvas) {
  //     canvas.on('mouse:over', (event) => {
  //       const {target} = event;
  //       setHoveredObject(target);
  //     });
  //     canvas.on('mouse:out', function (event) {
  //       const {target} = event;
  //       removeLabel(canvas, target);
  //       setHoveredObject(null);
  //     });
  //
  //     // Add condition to prevent object selection on the canvas when readOnly is true
  //     if (readOnly) {
  //       canvas.selection = false;
  //       canvas.forEachObject((object) => {
  //         object.selectable = false;
  //       });
  //     }
  //   }
  // }, [canvas]);

  // Add this useEffect hook to attach a click event listener to the canvas
  useEffect(() => {
    if (readOnly && canvas) {
      canvas.on('mouse:down', onCanvasClick);
    }
    return () => {
      if (readOnly && canvas) {
        canvas.off('mouse:down', onCanvasClick);
      }
    };
  }, [readOnly, canvas, onCanvasClick]);

  useEffect(() => {
    if ((renderStatus === SLIDE_STATUSES.CONTENT_LOADING)) {
      return;
    }
    renderFromJSON();
  }, [id, canvas]);

  useEffect(() => {
    if (isRendered) {
      renderFromJSON();
    }
  }, [canvas, isRendered]);

  const renderFromJSON = () => {
    if (!canvas) {
      return;
    }
    setIsLoadingSlide(true);
    canvas.loadFromJSON(slideJSON || {}, function () {
      // set selectable and evented properties based on readOnly prop
      canvas.forEachObject((obj) => {
        obj.set({
          selectable: !readOnly,
          evented: !readOnly,
        });
      });

      canvas.renderAll();
      setTimeout(() => {
        setIsLoadingSlide(false);
      }, 500);
    });
  }

  const removeLabel = (canvas, target) => {
    if (target && target.type === 'image' && target.photoCredits) {
      const _label = canvas.getObjects().find(_obj => _obj.id === 'label');
      canvas.remove(_label);
      canvas.renderAll();
    }
  };

  const renderImageLabel = () => {
    if (hoveredObject && hoveredObject.type === 'image' && hoveredObject.photoCredits) {
      const {url, username} = hoveredObject.photoCredits || {};
      addPhotoLabel(hoveredObject.photoCredits, hoveredObject, canvas);
      canvas.renderAll();
    }
  };

  useEffect(() => {
    if (!isLoading && canvas && canvasState && (renderStatus === SLIDE_STATUSES.COMPLETE)) {
      console.log('update slide');
      updateSlide(id, {state: canvasState, thumbnailSaved: null});
    }
  }, [canvasState]);

  useEffect(() => {
    if (!isLoading && thumbnail) {
      console.log('update thumbnail/slide');
      updateSlide(id, {thumbnail, thumbnailSaved: null});
    }
  }, [thumbnail]);

  const [regenerateImage, setRegenerateImage] = useState({
    canvasElement: null,
    newImageData: null
  });

  const handleAddTextClick = () => {
    let anotherText = canvas._objects.find((obj) => obj.id?.includes('text-'));
    if (!anotherText) {
      anotherText = canvas._objects.find((obj) => obj.id === 'title');
    }
    const newText = new fabric.Textbox('New text', {
      fontSize: anotherText?.fontSize || 18,
      fill: anotherText?.fill || textColor,
      fontFamily: 'Inter',
      width: 300
    });
    canvas.add(newText);
    newText.center();
    canvas.setActiveObject(newText);
  }

  const handleAddImage = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = function () {
        const image = new fabric.Image(imgObj);
        image.scaleToWidth(200);
        canvas.centerObject(image);
        canvas.add(image);
        canvas.renderAll();
      }
    }
    reader.readAsDataURL(file);
  }

  const handleToFrontClick = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.bringToFront();
      canvas.renderAll();
      updateData();
    }
  }

  const handleToBackClick = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.sendToBack();
      canvas.renderAll();
      updateData();
    }
  }

  const _replaceImage = (imageId, croppedImageB64, templateParams, photoCredits) => {
    replaceImage(canvas, regenerateImage.canvasElement, imageId, croppedImageB64, templateParams, photoCredits, updateData);
    setRegenerateImage({
      canvasElement: null,
      newImageData: null
    })
  };

  const regenerateObject = async (object, ai = false) => {
    setRegenerateInProgress(true);
    const type = object.get('type');
    if (type === 'image') {
      if (ai) {
        const {url, b64Data} = await fetchImage(presentationTopic, slideTopic);
        const fullUrl = `${FASTAPI_URL}${url}`;
        setRegenerateImage({
          canvasElement: object,
          newImageData: {
            baseUrl: fullUrl,
            photoCredits: '',
            baseB64: '',
            id: uuidv4(),
            height: 512,
            width: 512
          }
        });
      } else {
        const newImage = window.kesemSearchResults[Math.floor(Math.random() * window.kesemSearchResults.length)];
        setRegenerateImage({
          canvasElement: object,
          newImageData: {
            baseUrl: newImage.src.medium,
            photoCredits: {username: newImage.photographer, url: newImage.photographer_url},
            baseB64: '',
            id: uuidv4(),
            height: newImage.height,
            width: newImage.width
          }
        })
      }
    }
    if (type === 'textbox') {
      const response = await designSlides(slideTopic, presentationTopic, 1, sessionToken, user);
      const newTexts = convertTextToList(response.data.text);
      object.set({text: newTexts[Math.floor(Math.random() * newTexts.length)]});
      canvas.renderAll();
    }
    setRegenerateInProgress(false);
  }

  return (
    <>
      <div className={`presentation-slide-content ${(isLoadingSlide || isLoading) ? 'is-loading' : ''} ${(readOnly) ? 'read-only' : ''}`}>
        {!readOnly &&
        <PresentationTypographyControls onAddTextClick={handleAddTextClick}
                                        onAddImage={handleAddImage}
                                        onToFrontClick={handleToFrontClick}
                                        onToBackClick={handleToBackClick}
                                        readOnly={readOnly}
        />}
        <FullScreen handle={fullscreenHandle}>
          <div className={'presentation-slide-content__canvas'}
               ref={wrapRef}
               onClick={fullscreenHandle.active ? onFullscreenSlideClick : undefined}>
            <CanvasElementControls canvas={canvas} onRegenerate={regenerateObject} readOnly={readOnly}/>
            <canvas id={'canvas-main'}></canvas>
          </div>
          <Preloader isLoading={isLoadingSlide || regenerateInProgress || (fullscreenHandle.active && isLoading)}/>
        </FullScreen>
        <Preloader isLoading={(!fullscreenHandle.active && (isLoading || regenerateInProgress) )}/>
      </div>
      {Boolean(regenerateImage.newImageData) && <ImageCrop
        imagesData={[regenerateImage.newImageData]}
        imageTemplates={[]}
        onImageCrop={_replaceImage}
        imageRatio={regenerateImage.canvasElement.height / regenerateImage.canvasElement.width}
      />}
    </>
  );
};

export default PresentationSlideContent;
