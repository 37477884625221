import { useEffect, useState } from 'react';
import firebase from 'firebase';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FASTAPI_URL } from "../const/api";
import { ALLOWED_HOSTS } from "../const/api";


function useLogin() {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let redirectUri = params.get('redirect_uri');
    let state = params.get('state');

    useEffect(() => {
        // Check if the user clicked an email link
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            firebase.auth().signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    setUser(result.user);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setUser(user);

                user.getIdToken().then((idToken) => {
                    if (redirectUri) {
                        // If the user is successfully authenticated and a redirectUri is available, redirect the user.
                        // Include the ID token in the redirectUri as a query parameter.
                        const url = new URL(redirectUri);
                        const hostname = url.hostname;

                        url.searchParams.append('code', idToken);
                        url.searchParams.append('state', state);

                        // Check if the hostname is in the ALLOWED_HOSTS list or is a subdomain of an allowed host before redirecting.
                        if (ALLOWED_HOSTS.some(allowedHost => hostname === allowedHost || hostname.endsWith(`.${allowedHost}`))) {
                            window.location.href = url.toString();
                        }

                        // Enable this if we want to send a request to our server with the Firebase ID token,
                        // so our server can return an access token back.
                        // user.getIdToken().then((idToken) => {
                        //     axios.post(`${FASTAPI_URL}/auth/oauth_exchange`, {
                        //         code: idToken,
                        //         // Include additional parameters as required by your /auth/oauth_exchange endpoint.
                        //     }).then(() => {
                        //         // If the server responds successfully, redirect the user to the redirectUri.
                        //         window.location.href = redirectUri;
                        //     });
                        // });
                    }
                });
            } else {
                setUser(null);

            }
            setIsLoading(false);
        });
    }, []);

    const handleGoogleLogin = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider);
    };

    const handleEmailLinkLogin = (email) => {
        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true
        };
        firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleLinkedInLogin = () => {
        const provider = new firebase.auth.LinkedInAuthProvider();
        firebase.auth().signInWithPopup(provider);
    };

    const handleMicrosoftLogin = () => {
        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        firebase.auth().signInWithPopup(provider);
    };

    const handleSignOut = () => {
        firebase.auth().signOut();
    };

    return { user, isLoading, handleGoogleLogin, handleMicrosoftLogin, handleEmailLinkLogin, handleSignOut };
};

export default useLogin;
