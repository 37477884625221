import React from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import {v4 as uuidv4} from "uuid";
import slideTemplates from "../../../configs/slideTemplates.json";
import slideColors from "../../../configs/slideColors.json";
import {SLIDE_STATUSES} from "../../../const/slide";

const headerSlideTemplates = slideTemplates.headerSlide;
const baseSlideTemplates = slideTemplates.baseSlide;

const globalState = hookstate({
  topic: '',
  slideIds: [],
  slidesData: {},
  updateCounter: 0
});

export function usePresentationState() {
  const state = useHookstate(globalState);
  return ({
    get topic() {
      return state.topic.get();
    },
    get slideIds() {
      return state.slideIds.get();
    },
    get slidesData() {
      return state.slidesData.get();
    },
    get updateCounter() {
      return state.updateCounter.get();
    },
    get completeSlides() {
      return state.slideIds.get()
        .map(_id => state.slidesData[_id].get({ noproxy: true }))
        .filter(_slide => _slide.renderStatus === SLIDE_STATUSES.COMPLETE);
    },
    getSlideData(id) {
      return state.slidesData[id].get({ noproxy: true });
    },

    setLoadedState(_topic, _slides, thumbnailURL) {
      const _slidesState = {};
      const _slideIds = [];
      _slides.forEach((_slide, index) => {
        _slideIds.push(_slide.id);
        _slidesState[_slide.id] = {..._slide, renderStatus: SLIDE_STATUSES.CONTENT_LOADING};
        if (index === 0) {
          _slidesState[_slide.id] = {..._slidesState[_slide.id], thumbnail: thumbnailURL, isRendered: true};
        }
      });
      return state.set({
        slideIds: _slideIds,
        topic: _topic,
        slidesData: _slidesState,
        updateCounter: 0
      });
    },
    setTopic(_topic) {
      state.topic.set(_topic)
    },
    setSlideIds(_ids) {
      state.slideIds.set(_ids);
    },
    updateSlide(_id, updatedData, skipUpdate) {
      state.slidesData[_id].merge(updatedData);
      if (skipUpdate || (updatedData.renderStatus && (updatedData.renderStatus !== SLIDE_STATUSES.COMPLETE))) {
        return;
      }
      state.updateCounter.set(_oldState => _oldState + 1);
    },
    setNewSlides(topic, slideTitles) {
      const newState = {
        topic,
        slideIds: [],
        slidesData: {},
        updateCounter: 0
      };
      const _slideTitles = [topic, ...slideTitles];
      const colorScheme = slideColors[Math.floor(Math.random() * slideColors.length)];
      _slideTitles.forEach((_slideTitle, index) => {
        const headerTemplateIndex = Math.floor(Math.random() * headerSlideTemplates.length)
        const baseSlideTemplateIndex = Math.floor(Math.random() * baseSlideTemplates.length)
        const template = (index === 0) ?
          headerSlideTemplates[headerTemplateIndex] :
          baseSlideTemplates[baseSlideTemplateIndex];
        const id = uuidv4();
        newState.slideIds.push(id);
        newState.slidesData[id] = {
          id: uuidv4(),
          topic: _slideTitle,
          thumbnail: '',
          state: null,
          imageData: [],
          renderStatus: SLIDE_STATUSES.CONTENT_GENERATING,
          ...colorScheme,
          headerTemplateIndex,
          baseSlideTemplateIndex,
          template,
          isRendered: false,
          thumbnailSaved: null
        };
      });
      return state.set(newState);
    },
    resetState() {
      state.set({
        topic: '',
        slideIds: [],
        slidesData: {},
        updateCounter: 0
      });
    }
  });
}
