import * as React from "react";
import {useEffect} from "react";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import {isDev} from "../utils/env";

const usePageAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    if(!isDev) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: "Page view" });
    }
  }, [location]);
};

export default usePageAnalytics;
