import * as React from "react";
import Button from "../../../../components/Button/Button";
import './PresentationTypographyControls.css';
import {useRef} from "react";

const PresentationTypographyControls = ({
                                          onAddTextClick,
                                          onAddImage,
                                          onToFrontClick,
                                          onToBackClick,
                                          readOnly
                                        }) => {
  const inputRef = useRef(null);
  const handleAddImageClick = () => {
    inputRef.current.click();
  };
  const handleSelectedFileChange = (e) => {
    onAddImage(e.target.files[0]);
    inputRef.current.value = '';
  }
  return (
    <>
      <div className={'typography-controls'}>
        <Button tooltip={'Add text'} onClick={onAddTextClick} iconSrc={'/images/icons/typography-text.svg'}/>
        <Button tooltip={'Add image'} onClick={handleAddImageClick} iconSrc={'/images/icons/typography-image.svg'}/>
        <Button text={'To Front'} onClick={onToFrontClick}/>
        <Button text={'To Back'} onClick={onToBackClick}/>
      </div>
      <input className={'file-input'} type={'file'} ref={inputRef} onChange={handleSelectedFileChange}
             accept={'image/*'}/>
    </>
  );
};

export default PresentationTypographyControls;
