import * as React from "react";
import {Button, Row} from "antd";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import { StockOutlined, BarChartOutlined , PictureOutlined  } from '@ant-design/icons';
import { Link } from "react-router-dom";

const AppHeader = ({isAuthorized, readOnly=false, children}) => {
    const handleLogoClick = () => {
        window.location.href = '/';
    };

    const urlIs = (prefix) => {
        return window.location.pathname.endsWith(prefix);
    }

  return (
    <header className={'layout-header'}>
      <Row className={'row-container'}>
          <div className={'layout-header__logo'} onClick={handleLogoClick} style={{cursor: 'pointer'}}>
          <img alt={'logo'} src={isAuthorized || readOnly ? '/images/logo.svg' : '/images/logo-white.svg'} />
        </div>
        {children}
        {isAuthorized && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', margin: '10px 0', gap: '10px' }}>
          <Link to="/charts"><Button icon={<StockOutlined />} type={urlIs("/charts") ? "primary" : "default"} >CHARTS</Button></Link>
          <Link to="/analysis"><Button icon={<BarChartOutlined />} type={urlIs("/analysis") ? "primary" : "default"}>ANALYSIS</Button></Link>
          <Link to="/"><Button icon={<PictureOutlined />} type={urlIs("/") ? "primary" : "default"}>SLIDESHOWS</Button></Link>
        </div>}
        {isAuthorized && <ProfileMenu/>}
      </Row>
    </header>
  );
};

export default AppHeader;
