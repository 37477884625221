import * as React from "react";
import useLogin from "../../hooks/useLogin";
import {useLocation, useSearchParams, redirect, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {ROUTE as LOGIN_ROUTE} from '../login';
import {REDIRECT_URI_PARAM} from "./const";

const AuthorizedWrap = ({children}) => {
  const { user, isLoading} = useLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!isLoading && !user) {
      navigate(`${LOGIN_ROUTE}?${REDIRECT_URI_PARAM}=${encodeURIComponent(location.pathname)}`, {
        replace: true
      })
    }
  }, [user, isLoading]);
  if (isLoading) {
    return null;
  }
  if (!isLoading && !user) {
    return null;
  }
  return (
    <>
      {children}
    </>
  );
};

export default AuthorizedWrap;
