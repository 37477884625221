import * as React from "react";
import {Layout, Row, Col, Upload, Button, message, Card, Menu, Spin, Tag, Typography } from 'antd';
import { BarChartOutlined, ExclamationCircleOutlined, LoadingOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';
import './Analysis.css';
import {useEffect, useState} from "react";
import Preloader from "../../components/Preloader/Preloader";
import IdeasForm from "../ideas/components/IdeasForm/IdeasForm";
import {getAnalysis, getPrivateAnalysisResults, getUserFiles} from "../../requests/analysis";
import firebase from 'firebase';
import useLogin from "../../hooks/useLogin";
import axios from "axios";
import {COLORS} from "../../const/styles";
import PricingModal from "../presentation/components/PricingModal/PricingModal";
import {FASTAPI_URL} from "../../const/api";
import ReactGA from "react-ga4";
import {checkUserSubscription} from "../../requests/slides";
import AuthorizedWrap from "../login/AuthorizedWrap";
import Sidebar from "../../components/Sidebar/Sidebar";

const { Content, Sider } = Layout;
const { Paragraph } = Typography;
const { Dragger } = Upload;
const { Meta } = Card;


const Analysis = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [prompt, setPrompt] = useState([]);
    const [lastResponse, setLastResponse] = useState(null);
    const [resultList, setResultList] = useState([]);
    const [userFiles, setUserFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [resetKey, setResetKey] = useState(0);
    const [imageLoaded, setImageLoaded] = useState({});
    const [showSuggestedPrompts, setShowSuggestedPrompts] = useState(true);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState('');
    const [selectedKey, setSelectedKey] = useState('');
    const [analysisHistory, setAnalysisHistory] = useState('');
    const [analysisData, setAnalysisData] = useState([]);
    const [analysisResults, setAnalysisResults] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const { user } = useLogin();
    const [token, setToken] = React.useState(null);
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [isUserSubscribed, setIsUserSubscribed] = useState(false);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    React.useEffect(() => {
        const fetchToken = async () => {
            const currentUser = firebase.auth().currentUser;
            const token = await currentUser.getIdToken();
            setToken(token);
        };
        if (user) {
            fetchToken();
        }
    }, [user]);

    const fetchUserFiles = async () => {
        const data = await getUserFiles();
        const filteredData = data.filter(file => {
            const extension = file.split('.').pop().toLowerCase();
            return extension === 'csv' || extension === 'txt';
        });
        setUserFiles(filteredData);
    };

    React.useEffect(() => {
        if (user && token) {
            fetchUserFiles();
        }
    }, [user, token]);

    const handleSuggestedPromptClick = (prompt) => {
        // Paste the selected prompt into the search box
        console.log(prompt);
        setSelectedPrompt(prompt);
    };


    useEffect(() => {
        if (user && selectedSession) {
            // Subscribe to the real-time updates from Firebase (results collection)
            const unsubscribeResults = firebase
                .firestore().collection("chart_analysis_results")
                .where("user", "==", user.uid)
                .where("session", "==", selectedSession.id) // Assuming selectedSession.id is the session token
                .orderBy("timestamp", "asc")
                .onSnapshot(
                    (querySnapshot) => {
                        // reset previous results
                        setAnalysisResults([]);

                        // Handle real-time updates for results here
                        const resultsData = querySnapshot.docs.map((doc) => {
                            const data = doc.data();
                            return {
                                id: doc.id,
                                ...data,
                            };
                        });

                        console.log("resultsData", resultsData);

                        // Loop through the resultsData and update the state for each result
                        resultsData.forEach((resultData) => {
                            setAnalysisResults((prevResults) => [
                                ...prevResults,
                                {
                                    "id": resultData.id,
                                    "question": resultData.question,
                                    "analysis": resultData.analysis,
                                    "insights": resultData.insights,
                                    "actions": resultData.actions,
                                    "image": resultData.image,
                                    "code_chart": resultData.code_chart,
                                    "code_analysis": resultData.code_analysis,
                                    "status": resultData.status,
                                    "question_number": resultData.question_number,
                                    "truncated": resultData.truncated
                                },
                            ]);
                        });
                    },
                    (error) => {
                        console.log("Error getting documents: ", error);
                    }
                );

            // Clean up the subscription when the component is unmounted
            return () => {
                unsubscribeResults();
            };
        }
    }, [user, selectedSession]);


    useEffect(() => {
        if (user) {
            let isFirstLoad = true;

            // Subscribe to the real-time updates from Firebase
            const unsubscribe = firebase.firestore().collection("chart_analysis")
                .where("user", "==", user.uid)
                .orderBy("timestamp", "desc")
                .onSnapshot((querySnapshot) => {
                    const data = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        timestamp: doc.data().timestamp,
                        files: doc.data().files,
                        prompt: doc.data().prompt,
                        status: doc.data().status,
                        questions: doc.data().questions,
                    }));
                    setAnalysisHistory(data);
                    if (isFirstLoad) {
                        isFirstLoad = false;
                    }
                    else {
                        // firebase update (we either have a selectedSession, or it's a new session
                        // if it's a selected session we already have it in selectedSession
                        // if it's  a new one , we just set the selectedSession directly.
                        if (selectedSession?.id) {
                            const selectedSessionIndex = data.findIndex((session) => session.id === selectedSession.id);
                            if (selectedSessionIndex !== -1) {
                                applySelectedSession(data[selectedSessionIndex]);
                                setSelectedKey(selectedSessionIndex.toString());
                            }
                        }else if (data[0]?.id){
                            const selectedSessionIndex = data[0];
                            setAnalysisResults([]); // Clear the existing results
                            applySelectedSession(data[0]);
                            setSelectedKey(selectedSessionIndex.toString());
                        }
                    }
                    // this sets the right menu key.
                    // if it's a new session (selectedKey is null), set it to the first menu item
                    if (!selectedKey) {
                        setSelectedKey('1');

                    }
                }, (error) => {
                    console.log("Error getting documents: ", error);
                });

            // Clean up the subscription when the component is unmounted
            return () => {
                unsubscribe();
            };
        }
    }, [user]);

    const isSubscribed = async () => {
        try {
            const response = await checkUserSubscription();
            return response.data.is_subscribed
        } catch (error) {
            return false
        }
    };

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            const status = await isSubscribed();
            setIsUserSubscribed(status);
        };

        fetchSubscriptionStatus();
    }, []);


    useEffect(() => {
        // assuming you have a variable `isSubscribed` to hold the subscription status
        // and `id` to hold the id of the analysis
        if (isUserSubscribed && selectedSession) {
            getPrivateAnalysisResults(selectedSession?.id).then(privateResults => {
                // assuming `publicResults` holds the analysisResults from Firebase
                let publicResults = [...analysisResults];

                // merge public and private results
                // this naive merging assumes that no two results have the same id
                // improve as needed
                const mergedResults = [...publicResults, ...privateResults];

                setAnalysisResults(mergedResults);
            });
        }
    }, [isUserSubscribed, selectedSession]);

    const handlePromptSubmit = async (_prompt, selectedFiles) => {

        if (!selectedFiles || selectedFiles.length === 0) {
            message.warning("Please attach a file for the analysis.");
            return;
        }
        setIsInputDisabled(true); // Disable the input

        applySelectedSession(null);
        setIsLoading(true);
        setPrompt(_prompt);
        try{
            const data = await getAnalysis(_prompt, selectedFiles, selectedSession?.id);
            setLastResponse(data);
        } catch (error) {
            // user must subscribe
            if (error.response && error.response.status === 402) {
                ReactGA.event({
                    category: 'Analysis',
                    action: 'Reached limit',
                });
                message.info("You have reached your charts limit. Please subscribe");
                setShowPricingModal(true);
            } else {
                console.log(error);
                message.error("Unknown Error. Please try again");
            }
        }finally{
            setIsLoading(false);
        }
    };

    const updateUserFiles = (uploadedFile, status, percent=100) => {
        const updatedFile = {
            name: uploadedFile,
            status: status,
            percent: percent,
        };

        if (status === "done"){
            setUserFiles((prevState) => [...prevState, updatedFile.name]);
            setUploadedFiles((prevState) => [...prevState, updatedFile.name]);
        }
    };

    const customRequest = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                url: `${FASTAPI_URL}/user/upload/`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    updateUserFiles(file.name, 'uploading', percentCompleted);
                    onProgress({ percent: percentCompleted }, file);
                },
            });

            if (response.status === 200) {
                onSuccess(response.data, file);
                message.success(`${file.name} file uploaded successfully`);
                updateUserFiles(file.name, 'done');
            } else {
                onError(new Error('Upload failed.'));
                message.error(`${file.name} file upload failed.`);
                // updateUserFiles(file.name, 'error');
            }
        } catch (error) {
            if (error.response && error.response.status === 402) {
                ReactGA.event({
                    category: 'Analysis',
                    action: 'Reached files limit',
                });
                message.info("You have reached your files limit. Please subscribe");
                setShowPricingModal(true);
                // updateUserFiles(file.name, 'error');
            } else{
                onError(error);
                message.error(`${file.name} file upload failed.`);
                // updateUserFiles(file.name, 'error');
            }
        }
    };

    const uploadProps = {
        name: "file",
        openFileDialogOnClick: true,
        action: `${FASTAPI_URL}/user/upload/`,
        multiple: true,
        accept: ".csv",
        showUploadList: false,
        fileList: selectedFiles,
        headers:{
            Authorization: `Bearer ${token}`,
        },
        customRequest: customRequest,
    };

    const applySelectedSession = (selectedSession) => {
        // reset the search query (idea form)
        setResetKey((prevKey) => prevKey + 1);
        setImageLoaded({});
        setShowSuggestedPrompts(false);
        setSelectedSession(selectedSession);
        try{
            setSelectedFiles(
                selectedSession &&
                selectedSession.queries &&
                selectedSession.queries[selectedSession.queries.length - 1].files.length
                    ? selectedSession.queries[selectedSession.queries.length-1].files
                    : []
            );
        } catch (error){
            console.log("error", error)
        }

        // Enable the input only if it's a new session
        setIsInputDisabled(selectedSession !== null);
    };

    const handleCloseModal = () => {
        ReactGA.event({
            category: 'User Interaction (Analysis)',
            action: 'User closed Pricing Modal',
        });
        setShowPricingModal(false);
    };

    const handleSessionClick = (session) => {
        console.log('Session clicked:', session);
        setAnalysisResults([]); // Clear the existing results
        applySelectedSession(session);
        // console.log("selectedFiles after", selectedFiles);
    };

    const renderContent = () => {
        return(
            <Layout className={'page-layout'} style={{ minHeight: '100vh', backgroundColor: COLORS.bgMain}}>
                <Row>
                    <Sidebar contentWidth={260} isCollapsed={collapsed} setIsCollapsed={setCollapsed}>
                        <Col
                          className={'page-column'}
                          xs={24}
                          lg={6}
                          theme={"light"}
                          style={{backgroundColor: COLORS.bgMain, borderRight: "2px"}}
                        >
                            <div
                              style={{
                                  height: 32,
                                  margin: 16,
                              }}
                              onClick={() => {
                                  // set null as the current session
                                  applySelectedSession(null);

                                  setAnalysisResults([]);

                                  // remove the menu highlight
                                  setSelectedKey('');

                                  // Show the suggested prompts
                                  setShowSuggestedPrompts(true);

                                  // Enable the input only if it's a new session
                                  setIsInputDisabled(false);
                              }}
                            >
                                <Button type="default" icon={<BarChartOutlined />} style={{width: "calc(100% - 16px)"}}>New session</Button>
                            </div>
                            <Menu
                              style={{backgroundColor: COLORS.bgMain}}
                              selectedKeys={selectedKey !== '' ? [selectedKey] : []}
                              theme={"light"}>
                                {analysisHistory && analysisHistory
                                  .map((session, index) => (
                                    <Menu.Item key={index + 1} onClick={() => {
                                        handleSessionClick(session);
                                        setSelectedKey((index + 1).toString());
                                        setCollapsed(true);
                                    }}>
                                        {session?.status === "completed" ?
                                          <SmileOutlined style={{color: "green"}}/>
                                          : (Date.now() - session.timestamp.toMillis() <= 360000) ? <LoadingOutlined/> : <FrownOutlined />}
                                        {` ${session.prompt || session.queries && session?.queries[session.queries.length-1]?.query?.content}`}
                                    </Menu.Item>
                                  ))}
                            </Menu>
                        </Col>
                    </Sidebar>

                    <Col className={'page-column'} xs={24} lg={18}>
                        <Layout style={{ backgroundColor: COLORS.bgMain}}>
                            <Content className={'page-content'}>
                                <div>
                                    <IdeasForm
                                      onTopicSubmit={handlePromptSubmit}
                                      placeHolder={isInputDisabled ? selectedSession.prompt : selectedFiles.length > 0 ? "Add description, press Enter" : "Pick file, add description, press Enter"}
                                      errorMessage={"Fill in with something to continue..."}
                                      fileNames={userFiles.concat(uploadedFiles)}
                                      uploadProps={uploadProps}
                                      selectedPrompt={selectedPrompt}
                                      acceptedFiles={['.csv']}
                                      uploadedfFiles={uploadedFiles}
                                      initialSelectedFiles={selectedFiles}
                                      resetKey={resetKey}
                                      fontDecreaseSpeed={1.5}
                                      isInputDisabled={isInputDisabled}
                                      openFilesCollapseDefault={true}
                                    />
                                    <Spin indicator={antIcon} spinning={isLoading} style={{width: "100%", padding: "10px"}}/>

                                    {analysisResults && analysisResults.sort((a, b) => a.question_number - b.question_number).map((resultObject) => {
                                        const splitQuestion = resultObject.question.split("?");
                                        const question = splitQuestion.length > 1 ? `${splitQuestion[0]}?`: resultObject.question;
                                        const description = splitQuestion.length > 1 ? splitQuestion[1] : "...";
                                        return (
                                          <div key={resultObject.id} style={{padding: "2px", whiteSpace: "pre-line", flexDirection: "row"}}>
                                              <Card
                                                hoverable
                                                style={{ width: "100%" }}
                                              >
                                                  {/*<Meta title={question} description={description} />*/}
                                                  {/*<span>{resultObject.question}</span>*/}
                                                  {(resultObject.status !== "done") && (Date.now() - resultObject?.timestamp?.toMillis() <= 360000) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                      <div> Processing {resultObject.status}</div>
                                                      <Spin indicator={antIcon} spinning={true} style={{padding: "10px"}} size={"small"}/>
                                                  </div>}
                                                  <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '12px', wordBreak: 'break-word' }}>{question}</div>
                                                  <div style={{ fontSize: '14px', color: 'rgba(0,0,0,.45)', marginBottom: '16px', wordBreak: 'break-word' }}>{description}</div>
                                                  <Col style={{margin: "10px", border: "2px", whiteSpace: "pre-line"}}>
                                                      {resultObject.image &&  <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , width:"100%",  flexDirection: 'column' }}>
                                                          <img style={{width: "80%"}} src={`${FASTAPI_URL}/assets/${resultObject.image}`} />
                                                          {(!user || !isUserSubscribed) ? (
                                                            <div style={{ textAlign: "right", alignSelf:"flex-end", width: '100%' }}>
                                                                Remove the watermark?
                                                                <Tag
                                                                  onClick={
                                                                      () => {
                                                                          ReactGA.event({
                                                                              category: 'Analysis',
                                                                              action: 'Remove watermark press',
                                                                          });
                                                                          setShowPricingModal(true);
                                                                      }}
                                                                  style={{cursor: "pointer"}}> Subscribe
                                                                </Tag>
                                                            </div>
                                                          ) : null}
                                                      </Row>}
                                                      <div style={resultObject.truncated ? {filter: 'blur(1.2px)'} : {}}>
                                                          <div style={{paddingBottom: "10px"}}>
                                                              {(resultObject?.analysis?.length > 0) &&  <Row style={{whiteSpace: "pre-line", fontWeight: 'bold'}}><span>Analysis:</span></Row>}
                                                              {resultObject?.analysis?.length > 0 && resultObject?.analysis.map((item, index) => <Row key={index} style={{whiteSpace: "pre-line"}}><span>{item}</span></Row>)}
                                                          </div>
                                                          <div style={{paddingBottom: "10px"}}>
                                                              {(resultObject?.insights?.length > 0) && <Row style={{whiteSpace: "pre-line", fontWeight: 'bold'}}>Insights:</Row>}
                                                              {resultObject?.insights?.length > 0 && resultObject?.insights.map((insight, index) => <Row key={index} style={{whiteSpace: "pre-line"}}><span>{insight}</span></Row>)}
                                                          </div>
                                                          <div style={{paddingBottom: "10px"}}>
                                                              {(resultObject?.actions?.length > 0) && <Row style={{whiteSpace: "pre-line", fontWeight: 'bold'}}>Action Items:</Row>}
                                                              {resultObject?.actions?.length > 0 && resultObject?.actions.map((action, index) => <Row key={index} style={{whiteSpace: "pre-line"}}><span>{action}</span></Row>)}
                                                          </div>
                                                      </div>
                                                      {/* Show 'full analysis' button if truncated is true */}
                                                      <div style={{ flexDirection: 'column' , display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                          {resultObject.truncated && (
                                                            <>
                                                                <span>Subscribe to see the full analysis...</span>
                                                                <Button type={"primary"} onClick={() => {
                                                                    ReactGA.event({
                                                                        category: 'Analysis',
                                                                        action: 'See full analysis',
                                                                    });
                                                                    setShowPricingModal(true);
                                                                }}>Show full analysis</Button>
                                                            </>
                                                          )}
                                                      </div>
                                                  </Col>
                                              </Card>
                                          </div>
                                        )
                                    })}
                                    {selectedSession && <div style={{ flexDirection: 'column' , display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <div style={{marginTop: "10px", paddingRight: "5px", display:"flex", flexDirection: "row"}}><h2>Status: {selectedSession.status}</h2>{selectedSession.status !== "completed" && (Date.now() - selectedSession.timestamp.toMillis() <= 360000) && <Spin indicator={antIcon} spinning={true} size={"small"}/>}</div>
                                        {selectedSession && selectedSession.questions && selectedSession.status !== "completed" && <div style={{ flexWrap: "wrap", marginTop: "10px"}}>{selectedSession.questions.length > 0 && <div>Generated Analysis: </div>}{selectedSession.questions.map(q=> (<Paragraph>{q}</Paragraph>))}</div>}
                                        <div style={{marginTop: "10px"}}><h2>{selectedSession.prompt}</h2></div>
                                        <div style={{marginTop: "10px"}}>{selectedSession.files.map(filename => (<Tag color={"cyan"}>{filename}</Tag>))}</div>
                                    </div>}
                                </div>
                                <PricingModal isShown={showPricingModal} onCancel={handleCloseModal}/>
                            </Content>
                        </Layout>
                    </Col>

                </Row>
            </Layout>
        );
    };

    return (
        <>
            {renderContent()}
            <Preloader isLoading={isLoading}/>
        </>
    )
};

const WrapperAnalysis = (props) => {
    return <AuthorizedWrap>
        <Analysis {...props}/>
    </AuthorizedWrap>
}

export default WrapperAnalysis;
