import * as React from "react";
import LandingApp from "./main/LandingApp";
import GlobalStyles from './styles/GlobalStyles';
import { css } from "styled-components/macro";
import SaaSProductLandingPage from "./demos/SaaSProductLandingPage"; //eslint-disable-line
import  Terms  from './pages/TermsOfService';
import PrivacyPolicy  from './pages/PrivacyPolicy';


export const LandingPage = () => {
  return (
    <>
      <GlobalStyles />
      <SaaSProductLandingPage/>
    </>

  );
};

export const TermsPage = () => {
  return (
    <>
      <GlobalStyles />
      <Terms/>
    </>

  );
};

export const PrivacyPage = () => {
  return (
    <>
      <GlobalStyles />
      <PrivacyPolicy/>
    </>

  );
};

export default LandingPage;
