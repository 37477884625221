import axios from "axios";
import { FASTAPI_URL } from "../const/api";
import firebase from 'firebase';

export async function getAnalysis(prompt, fileNames, sessionToken) {
  let token = await firebase.auth().currentUser.getIdToken();
    const suffix = sessionToken  ? `?session=${sessionToken}` : "";
    const apiUrl = `${FASTAPI_URL}/analysis/${suffix}`;

    let dataToSend = {
        csv_files: fileNames,
        prompt: prompt,
    };
    if (sessionToken) {
        dataToSend.sessionToken = sessionToken;
    }
    let headers = {
        'Authorization': `Bearer ${token}`
    };

    const response = await axios.post(apiUrl, dataToSend, {
        headers: headers
    });
    return response.data
}

export async function getUserFiles(){
  let token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.get(`${FASTAPI_URL}/user/files/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(`response ${JSON.stringify(response)}`);
  return response.data
}

export async function getPrivateAnalysisResults(id) {
    let token = await firebase.auth().currentUser.getIdToken();

    const response = await axios.get(`${FASTAPI_URL}/analysis-results/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    console.log(`response ${JSON.stringify(response)}`);
    return response.data
}