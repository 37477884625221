import {pdf} from '@react-pdf/renderer';
import {saveAs} from 'file-saver';
import * as React from 'react';
import ReactPDF, { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FAFAFA',
    fontSize: 10,
    lineHeight: 1.3,
    padding: '65px 10px 10px',
  }
});

export const generatePDF = async (title, slideImages, isDownload) => {
  const blob = await pdf((
    <Document>
      {slideImages.map((imageSrc, index) => {
        return <Page key={index} size="A4" style={styles.page} orientation={'landscape'} wrap>
          <View>
            <Image src={imageSrc}/>
          </View>
        </Page>
      })}
    </Document>
  )).toBlob();
  if (isDownload) {
    saveAs(blob, title);
  } else {
    const fileURL = window.URL.createObjectURL(blob);
    window.open(fileURL);
  }
};
