import * as React from "react";
import "./SlidesPrerender.css";
import SlidesRendererItem from "./SlidesRendererItem";
import {usePresentationState} from "../../state/presentation";

const SlidesRenderer = ({presentationTopic, sessionToken}) => {
  const {slideIds} = usePresentationState();
  return (
    <div className={'slides-prerender-wrap'}>
      {slideIds.map(_id => <SlidesRendererItem key={_id}
                                               slideId={_id}
                                               presentationTopic={presentationTopic}
                                               sessionToken={sessionToken}
      />)}
    </div>
  );
};

export default SlidesRenderer;
