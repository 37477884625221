import * as React from "react";
import PresentationThumbnail from "./PresentationThumbnail";
import {usePresentationState} from "../../state/presentation";
import {SLIDE_STATUSES} from "../../../../const/slide";

const Thumbnails = ({currentSlideIndex, onThumbnailClick}) => {
  const {slideIds, slidesData} = usePresentationState();
  return (
    <div className={'presentation-thumbnails'}>
      {slideIds.map((_slideId, index) => <PresentationThumbnail key={_slideId}
                                                                id={_slideId}
                                                                index={index}
                                                                onThumbClick={onThumbnailClick}
                                                                isActive={index === currentSlideIndex}
                                                                backgroundColor={slidesData[_slideId].backgroundColor}
                                                                thumbnail={slidesData[_slideId].thumbnail}
                                                                isLoading={slidesData[_slideId].renderStatus !== SLIDE_STATUSES.COMPLETE}
      />)}

    </div>
  );
};

export default Thumbnails;
