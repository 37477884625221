import * as React from "react";
import { Modal } from 'antd';
import './PricingModal.css';
import useLogin from "../../../../hooks/useLogin";

const PricingModal = ({isShown, onCancel}) => {
  const {user} = useLogin();

  return (
    <Modal className={'pricing-modal'}
           width={'auto'}
           open={isShown}
           closable={false}
           footer={null}
           onCancel={onCancel}>
      {isShown && <stripe-pricing-table
        pricing-table-id={"prctbl_1Mo6PLA8SLIZAvkf89DvrIX1"}
        publishable-key={"pk_live_wdBcjMsxqaTmXpziW8VtR6ms00Bln3ZTLK"}
        client-reference-id={user.uid}
        customer-email={user.email}
        style={{width: "100%"}}
      >
      </stripe-pricing-table>}
    </Modal>
  );
};

export default PricingModal;
