import pptxgen from "pptxgenjs";
import {CANVAS_BASE_HEIGHT, CANVAS_BASE_WIDTH} from "../../../const/canvas";
import {SLIDE_STATUSES} from "../../../const/slide";

function clearColor(color) {
  let result = color;
  if (result[0] === "#") {
    result = color.slice(1);
  }
  if (result.length === 3) {
    result = result[0] + result[0] + result[1] + result[1] + result[2] + result[2];
  }
  return result;
}

function convertPixelsToPercentage(pixelsValue, zoom, isVertical) {
  const referenceValue = isVertical ? CANVAS_BASE_HEIGHT : CANVAS_BASE_WIDTH;
  return `${(pixelsValue * zoom) / referenceValue * 100}%`;
}

export function generatePresentation(presentationTopic, slides) {
  let pres = new pptxgen();

  function createCreditsSlideTexts(slide) {
    // Add the credits slide directly
    const creditsSlide = pres.addSlide();

    creditsSlide.background = {
      color: clearColor(slide.backgroundColor)
    };

    // Add the title "Credits"
    creditsSlide.addText("Credits", {
      x: '10%',
      y: '5%',
      w: '80%',
      h: '10%',
      fontSize: 24,
      fontFace: 'Arial',
      bold: true,
      align: 'left',
      color: clearColor(slide.color),
    });
    console.log('slideText in download', slide.slideText);
    creditsSlide.addText(slide.slideText.join("\n"), {
      x: '10%',
      y: '10%',
      w: '80%',
      h: '80%',
      fontSize: 12,
      fontFace: 'Arial',
      lineSpacing: 14,
      align: 'left',
      color: clearColor(slide.color),
    });
  }

  slides.forEach((slide) => {
    if (slide.renderStatus === SLIDE_STATUSES.COMPLETE) {
      let page = pres.addSlide();
      page.background = {
        color: clearColor(slide.backgroundColor)
      };
      slide.state.objects.forEach((object) => {
        const {left, top, width, height, scaleX, scaleY} = object;
        if (object.type === "textbox") {
          page.addText(object.text, {
            x: convertPixelsToPercentage(left, scaleX, false),
            y: convertPixelsToPercentage(top, scaleY, true),
            w: convertPixelsToPercentage(width, scaleX, false),
            h: convertPixelsToPercentage(height, scaleY, true),
            color: clearColor(object.fill),
            fontSize: object.fontSize,
            fontFace: object.fontFamily,
            lineSpacing: object.lineHeight * object.fontSize,
            align: object.textAlign,
          });
        } else if (object.type === "image") {
          page.addImage({
            path: object.src,
            x: convertPixelsToPercentage(left, 1, false),
            y: convertPixelsToPercentage(top, 1, true),
            w: convertPixelsToPercentage(width, scaleX, false),
            h: convertPixelsToPercentage(height, scaleY, true),
          });
        }
      });
    }
    else if (slide.isCreditSlide){
      createCreditsSlideTexts(slide);
    }
  });

  pres.writeFile({ fileName: `${presentationTopic} presentation.pptx` });
}

export function shuffle(array) {
  return [...array].sort(() => Math.random() - 0.5);
}

export function convertTextToList(text) {
  return text.split('\n').map(item => item.replace(/^[0-9.~\s\-•]+/, '')).filter(item => item.trim());
}

export function copyToClipboard(text){
  const textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed";
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};
