import React, {useEffect} from 'react';
import {Main} from "./modules/main";
import {MEASUREMENT_ID} from "./const/api";
import ReactGA from "react-ga4";
import './App.css';
import usePageAnalytics from "./hooks/usePageAnalytics";
import { Route, Routes} from "react-router-dom";
import {LandingPage, ROUTE as LANDING_ROUTE} from "./modules/landing";

ReactGA.initialize(MEASUREMENT_ID);

function App(){
    usePageAnalytics();
    return (
        <div className="App">
            <Routes>
              <Route path={LANDING_ROUTE} element={<LandingPage />} />
              <Route path={'*'} element={<Main />} />
            </Routes>
        </div>
    );
};

export default App;


