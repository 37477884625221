import * as React from "react";
import { Dropdown, Space } from 'antd';
import {ArrowDownOutlined} from "@ant-design/icons";
import Button from "../../../../components/Button/Button";

const DownloadButton = ({onPresentationDownloadClick, onPDFDownloadClick}) => {
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const  handlePDFDownloadClick = async () => {
    setPdfLoading(true);
    await onPDFDownloadClick();
    setPdfLoading(false);
  };
  const items = [
    {
      key: '1',
      label: (
        <div onClick={onPresentationDownloadClick}>
          PowerPoint
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={handlePDFDownloadClick}>
          PDF
        </div>
      ),
      disabled: pdfLoading
    }
  ];
  return (
    <Dropdown menu={{ items }} trigger={'click'}>
      <div className={'profile-menu-anchor'}>
        <Button icon={<ArrowDownOutlined style={{fontSize: 18}}/>} tooltip={'Export'} text={'Download'}/>
      </div>
    </Dropdown>
  );
};

export default DownloadButton;
