import * as React from "react";
import "./CanvasEmelentControls.css";
import Button from "../../../../components/Button/Button";
import { RetweetOutlined, DeleteOutlined } from '@ant-design/icons';
import {useEffect, useState} from "react";
import {CANVAS_BASE_WIDTH} from "../../../../const/canvas";
import Icon from '@ant-design/icons';
import { ReactComponent as AiSVG } from "../../../../openai-svgrepo-com.svg";

const CanvasElementControls = ({canvas, onRegenerate, readOnly }) => {
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [position, setPosition] = useState({top: 0, left: 0});
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    if (canvas) {
      setListeners();
    }
  }, [canvas]);

  function setListeners() {
    canvas.on({
      'selection:cleared': handleSelectionChange,
      'selection:created': handleSelectionChange,
      'selection:updated': handleSelectionChange,
      'object:modified': updateButtonsPosition,
      'before:transform': handleObjectChangeStart,
    })
  }

  function handleSelectionChange() {
    setTimeout(() => {
      setSelectedObjects(canvas.getActiveObjects());
      updateButtonsPosition();
    }, 0);
  }

  function handleObjectChangeStart() {
    setIsHidden(true);
  }

  function updateButtonsPosition() {
    setIsHidden(false);
    const firstSelectedObj = canvas.getActiveObjects()[0];
    if (firstSelectedObj) {
      const sizeDiff = canvas.width / CANVAS_BASE_WIDTH;
      setPosition({
        left: firstSelectedObj.left * sizeDiff,
        top: firstSelectedObj.top * sizeDiff - 60
      })
    }
  }

  function handleRegenerate() {
    onRegenerate(selectedObjects[0]);
  }

  function handleRegenerateAI() {
    onRegenerate(selectedObjects[0], true);
  }

  function handleDelete() {
    canvas.remove(...selectedObjects);
    canvas.discardActiveObject().renderAll();
  }
  const AiIcon = () => <Icon component={AiSVG} />;
  const regenerateAvailable = (selectedObjects.length === 1) && (!selectedObjects[0].id?.includes('title'));
  const isImage = (selectedObjects.length === 1) && (selectedObjects[0].type==="image");
  return (
    <div className={`canvas-element-controls ${(selectedObjects.length && !isHidden) ? '' : 'is-hidden'}`} style={
      {...position}
    }>
      {!readOnly && regenerateAvailable && <Button onClick={handleRegenerate} icon={<RetweetOutlined />} tooltip={'Regenerate'}/>}
      {!readOnly && (
          <Button onClick={handleDelete} icon={<DeleteOutlined />} tooltip={'Delete'} />
      )}
      {!readOnly && regenerateAvailable && isImage && <Button onClick={handleRegenerateAI} icon={<AiIcon  />} tooltip={'AI Art'}/>}
    </div>
  );
};

export default CanvasElementControls;
