import * as React from "react";
import './PresentationList.css';
import PresentationPreview from "../../../presentation/components/PresentationPreview/PresentationPreview";
import { Row } from 'antd';

const PresentationsList = ({slideshowList}) => {
  return (
    <div className={'presentation-list'}>
      <div className={'presentation-list-title'}>Your past presentations</div>
      <Row gutter={[32, 24]}>
        {slideshowList.map((presentation) => <PresentationPreview key={presentation.id} presentation={presentation}/>)}
      </Row>
    </div>
  );
};

export default PresentationsList;
