import * as React from "react";
import {Button, Checkbox, Input, List, Switch} from "antd";
import "./IdeasList.css";

const IdeasList = ({topic, topicItems, onTopicItemsSubmit, onGenerateMoreTopicItems}) => {
  const [selectedItems, setSelectedItems] = React.useState([...topicItems]);

  const handleCheckedChange = (_item) => {
    if (selectedItems.includes(_item)) {
      setSelectedItems(selectedItems.filter((item) => item !== _item));
    } else {
      setSelectedItems([...selectedItems, _item]);
    }
  };
  const handleCheckedSubmit = () => {
    onTopicItemsSubmit(selectedItems);
  };

  return (
    <div className={'ideas-list-page'}>
      <div className={'ideas-list-title'}>{topic}</div>
      <div className={'ideas-list-wrap'}>
        <div className={'ideas-list-header'}>
          <div className={'ideas-list-header__title'}>Choose the topics for your presentation slides</div>
        </div>
        <List
          className={'ideas-list'}
          dataSource={topicItems}
          renderItem={(item, index) => (
            <List.Item
              className={'ideas-list-item'}
              key={item}
            >
              <Checkbox
                checked={selectedItems.includes(item)}
                onChange={() => {handleCheckedChange(item)}}
              />
              <p style={{display: "inline", marginRight: "3px"}}>{`${index + 1}. `}</p>
              {item}
            </List.Item>
          )}
          // onChange={handleMove}
        />
      </div>

      <div>
        <Button onClick={onGenerateMoreTopicItems} style={{marginRight: 16}}>Generate more slide topics</Button>
        <Button disabled={!selectedItems.length} type={'primary'}
                onClick={handleCheckedSubmit}>Create the presentation</Button>
      </div>
    </div>
  );
};

export default IdeasList;
