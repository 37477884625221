import * as React from "react";
import {uploadImage} from "../../../../requests/slides";
import {FASTAPI_URL} from "../../../../const/api";
import {savePresentation} from "../../../../requests/presentation";
import {useEffect, useRef} from "react";
import useLogin from "../../../../hooks/useLogin";
import {usePresentationState} from "../../state/presentation";

const PresentationEventHandler = ({presentationId, sessionToken}) => {
  const {user} = useLogin();
  const timeoutRef = useRef(0);
  const {completeSlides, topic, slideIds, updateCounter, updateSlide, getSlideData} = usePresentationState();

  useEffect(() => {
    if (updateCounter > 0) {
      handlePresentationUpdate();
    }
    return () => {
      clearTimeout(timeoutRef.current);
    }
  }, [updateCounter]);

  const saveThumbnail = async () => {
    const firstSlide = getSlideData(slideIds[0]);
    if (firstSlide.state) {
      if (firstSlide.thumbnailSaved) {
        return firstSlide.thumbnailSaved;
      }
      const uniqueFilename = `slide-${firstSlide.id}-thumbnail.png`;
      try {
        // console.log("uploading slide thumbnailBase64", uniqueFilename);
        const response = await uploadImage(firstSlide.thumbnail, uniqueFilename);
        const thumbnailURL = `${FASTAPI_URL}${response.data.file}`;
        updateSlide(firstSlide.id, {thumbnailSaved: thumbnailURL}, true);
        return thumbnailURL;
      } catch (error) {
        return null
      }
    }
  };

  const handlePresentationUpdate = async () => {
    clearTimeout(timeoutRef.current);
    if (completeSlides.length) {
      timeoutRef.current = setTimeout(async () => {
        const thumbnailURL = await saveThumbnail();
        savePresentation(presentationId, thumbnailURL, completeSlides, topic, user, sessionToken);
      }, 5000);
    }
  };
  return (
    <></>
  );
};

export default PresentationEventHandler;
