import * as React from "react";
import "./Presentation.css";
import {useEffect, useState} from "react";
import {useFullScreenHandle} from "react-full-screen";
import {v4 as uuidv4} from 'uuid';
import {Modal} from "antd";
import {ArrowLeftOutlined, LinkOutlined, CaretRightOutlined} from '@ant-design/icons';
import {copyToClipboard, generatePresentation} from "../../../ideas/utils/presentation";
import Button from "../../../../components/Button/Button";
import DownloadButton from "./DownloadButton";
import {generatePDF} from "../../../../utils/pdf";
import PricingModal from "../PricingModal/PricingModal";
import SlidesRenderer from "../SlidesRenderer/SlidesRenderer";
import {updatePresentationSharedStatus} from "../../../../requests/presentation";
import {useNavigate, createSearchParams} from "react-router-dom";
import {ROUTE} from "../../index";
import {checkUserSubscription, uploadImage} from "../../../../requests/slides";
import {createCreditsSlide} from "../../../../utils/slide";
import {usePresentationState} from "../../state/presentation";
import Thumbnails from "../Thumbnails/Thumbnails";
import PresentationSlideContent from "./PresentationSlideContent";
import PresentationEventHandler from "./PresentationEventHandler";
import ReactGA from "react-ga4";
import {isDev} from "../../../../utils/env";
import {SLIDE_STATUSES} from "../../../../const/slide";

const Presentation = ({topic, onBack, presentationId, sessionToken, readOnly = false}) => {
  const navigate = useNavigate();
  const handle = useFullScreenHandle();
  const { slideIds, completeSlides } = usePresentationState();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [showPricingModal, setShowPricingModal] = React.useState(false);
  const [showSharedModal, setShowSharedModal] = useState(false);

  const presentationRef = React.useRef((presentationId === "new") ? uuidv4() : presentationId);

  useEffect(() => {
    if (handle.active) {
      setCurrentSlide(0);
    }
  }, [handle.active]);

  useEffect(() => {
    if (presentationId === "new") {
      navigate({
        pathname: ROUTE.replace(':presentationId', presentationRef.current),
        search: createSearchParams({sessionToken: sessionToken}).toString()
      }, {replace: true});
    }
  }, [presentationId]);

  const handleThumbnailClick = id => {
    setCurrentSlide(slideIds.findIndex(_slideId => _slideId === id));
    console.log(presentationRef.current);
  };

  const handleFullscreenSlideClick = () => {
    if (slideIds[currentSlide + 1]) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setIsFullscreen(false);
      handle.exit();
    }
  }

  const handleFullscreenOnClick = () => {
    setIsFullscreen(true);
    handle.enter();
  }

  const isSubscribed = async () => {
    try {
      const response = await checkUserSubscription();
      return response.data.is_subscribed
    } catch (error) {
      return false
    }
  };

  const handlePresentationDownload = async () => {
    if (!isDev) {
      ReactGA.event({
        category: "User Action",
        action: "Download",
        label: "PowerPoint download",
      });
    }

    const isSub = await isSubscribed();
    if (isSub) {
      const slidesWithCredits = [...completeSlides];
      generatePresentation(topic, slidesWithCredits);
    } else {
      setShowPricingModal(true);
    }
  };

  const handlePDFDownload = async () => {
    if (!isDev) {
      ReactGA.event({
        category: "User Action",
        action: "Download",
        label: "PDF download",
      });
    }

    const isSub = await isSubscribed();
    // console.log("isSub", isSub);
    if (isSub) {
      const slidesWithCredits = [...completeSlides];
      return generatePDF(topic, slidesWithCredits.filter(_slide => _slide.thumbnail).map(_slide => _slide.thumbnail), true);
    } else {
      setShowPricingModal(true);
    }
  };

  const handleCloseModal = () => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'User closed Pricing Modal',
    });
    setShowPricingModal(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      if (slideIds[currentSlide + 1]) {
        setCurrentSlide(currentSlide + 1);
      }
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentSlide, slideIds]);

  const showHideShareModal = () => {
    // Show the overlay popup (Modal)
    setShowSharedModal(true);

    // Optionally, you can hide the overlay popup after a few seconds
    setTimeout(() => setShowSharedModal(false), 3000);
  };

  const handleSharePresentation = () => {
    // No need to share in firebase we're already in shared (read only) state
    if (readOnly) {
      copyToClipboard(window.location.origin);
      showHideShareModal();
    } else {
      // Update the isShared flag to true in Firestore
      updatePresentationSharedStatus(presentationRef.current, true)
        .then(() => {
          // Copy the shared presentation link to the clipboard
          const sharedLink = `${window.location.origin}/shared/${presentationRef.current}`;
          copyToClipboard(sharedLink);

          showHideShareModal()
        })
        .catch((error) => {
          console.error("Error updating shared status:", error);
        })
    }
    if (!isDev) {
      ReactGA.event({
        category: "User Action",
        action: "Share",
        label: "Presentation Shared",
      });
    }
  }

  return (
    <div className={'presentation-wrap'}>
      <div className={`presentation-controls ${readOnly ? 'read-only' : ''}`}>
        {!readOnly &&
          <div className={'presentation-controls-left'}>
            <div className={'link'} onClick={onBack}>
              <ArrowLeftOutlined/>
              <span style={{marginLeft: 9}}>Back to search</span>
            </div>
          </div>}
        <div className={'presentation-controls-right'}>
          {/*{!readOnly && <Button isDisabled={true} text={'Refresh the design'}/>}*/}
          {!readOnly && <DownloadButton
            onPresentationDownloadClick={handlePresentationDownload}
            onPDFDownloadClick={handlePDFDownload}
          />}
          <Button icon={<LinkOutlined style={{fontSize: 18}}/>} tooltip={'Share'} onClick={handleSharePresentation} isDisabled={!(completeSlides.map(x => x.renderStatus === SLIDE_STATUSES.COMPLETE)).every(x => x===true)}/>
          <Button icon={<CaretRightOutlined style={{fontSize: 18}}/>}
                  tooltip={'Preview'}
                  onClick={handleFullscreenOnClick}/>
        </div>
        <div></div>
      </div>
      <div className={`presentation-title ${(readOnly) ? 'read-only' : ''}`}>{topic} presentation</div>
      <div className={`presentation ${(readOnly) ? 'read-only' : ''}`}>
        {!readOnly && <Thumbnails currentSlideIndex={currentSlide} onThumbnailClick={handleThumbnailClick}/>}
        <PresentationSlideContent
          presentationTopic={topic}
          id={slideIds[currentSlide]}
          fullscreenHandle={handle}
          onFullscreenSlideClick={handleFullscreenSlideClick}
          isFullscreen={isFullscreen}
          readOnly={readOnly} // Pass the readOnly prop to PresentationSlideContent
          onCanvasClick={handleFullscreenSlideClick}
          sessionToken={sessionToken}
        />
      </div>
      <Modal
        title={null}
        open={showSharedModal}
        onCancel={() => setShowSharedModal(false)}
        footer={null}
        closable={false}
        style={{top: "80%"}}
      >
        <p>Copied link to clipboard!</p>
      </Modal>
      <SlidesRenderer currentSlideIndex={currentSlide}
                      presentationTopic={topic}
                      sessionToken={sessionToken}/>
      <PricingModal isShown={showPricingModal} onCancel={handleCloseModal}/>
      <PresentationEventHandler presentationId={presentationRef.current} sessionToken={sessionToken}/>
    </div>
  );
};

export default Presentation;
