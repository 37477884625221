import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {fabric} from "fabric";
import {CANVAS_BASE_HEIGHT, CANVAS_BASE_WIDTH, CANVAS_H_TO_W_RATIO, CANVAS_JSON_PARAMS} from "../../../../const/canvas";
import {v4 as uuidv4} from "uuid";
import {addImageToSlide} from "../../../../utils/slide";
import ImageCrop from "../ImageCrop/ImageCrop";
import {designSlides, fetchImage} from "../../../../requests/slides";
import {convertTextToList} from "../../../ideas/utils/presentation";
import useLogin from "../../../../hooks/useLogin";
import {usePresentationState} from "../../state/presentation";
import {SLIDE_STATUSES} from "../../../../const/slide";
import {FASTAPI_URL} from "../../../../const/api";

const CANVAS_WIDTH = 200;
const CANVAS_HEIGHT = CANVAS_WIDTH * CANVAS_H_TO_W_RATIO;
const CANVAS_ZOOM = CANVAS_WIDTH / CANVAS_BASE_WIDTH;

const SlidesRendererItem = ({slideId, presentationTopic, sessionToken}) => {
  const canvasId = `canvas-${slideId}`;
  const {getSlideData, updateSlide} = usePresentationState();
  const slideData = getSlideData(slideId);
  const canvasRef = useRef(null);
  const {template, backgroundColor, topic, color: textColor} = slideData
  const isRenderedItemsRef = useRef({
    text: false,
    image: false,
    isImageFinal: false
  });
  const {user} = useLogin();
  const [renderImagesData, setRenderImagesData] = useState({
    imagesData: [],
    templates: []
  });

  useEffect(() => {
    canvasRef.current = new fabric.Canvas(canvasId, {preserveObjectStacking: true});
    updateCanvasDimensions();
    renderContentInitial();
  }, []);

  useEffect(() => {
    if (slideData.imageData?.length && !(slideData.renderStatus === SLIDE_STATUSES.CONTENT_LOADING)) {
      setRenderImagesData({
        imagesData: slideData.imageData,
        templates: template.images
      })
    }
    if (!slideData.state && !slideData.imageData?.length) {
      generateInitialImage();
    }
  }, [slideData.imageData]);

  async function generateInitialImage() {
    const {url, b64Data} = await fetchImage(presentationTopic, slideData.topic);
    setRenderImagesData({
      imagesData: [{
        id: null,
        photoCredits: null,
        baseUrl: `${FASTAPI_URL}${url}`,
        height: 512,
        width: 512
      }],
      templates: template.images
    })
  }

  const updateCanvasDimensions = () => {
    canvasRef.current.setDimensions({width: CANVAS_WIDTH, height: CANVAS_HEIGHT});
    canvasRef.current.setZoom(CANVAS_ZOOM);
  }

  const renderContentInitial = () => {
    if (slideData.state) {
      loadFromJSON();
    } else {
      addBackground();
      addTitle();
      addText();
    }
  };

  const loadFromJSON = () => {
    canvasRef.current.loadFromJSON(slideData.state || {}, function () {
      canvasRef.current.renderAll();
      updateSlide(slideId, {
        renderStatus: SLIDE_STATUSES.COMPLETE,
        state: canvasRef.current.toJSON(CANVAS_JSON_PARAMS),
        thumbnail: canvasRef.current.toDataURL({
          format: 'png',
          quality: 1
        })
      });
    });
  }

  const addBackground = () => {
    const grad = new fabric.Gradient({
      type: 'linear',
      coords: {
        x2: -canvasRef.current.width,
        y2: -canvasRef.current.height / 2,
        x1: canvasRef.current.width,
        y1: canvasRef.current.height / 2,
      },
      colorStops: [
        {
          color: backgroundColor,
          offset: 0.2,
        },
        {
          color: '#000000',
          offset: 0,
        }
      ]
    });
    canvasRef.current.setBackgroundColor(grad);
  }

  const addTitle = () => {
    const id = template.id;
    const {fontSize, left, top, width, textAlign} = template.title;
    let additionalOptions = {};
    if (id === 'FULLSCREEN_IMAGE') {
      additionalOptions = {
        ...additionalOptions,
        // stroke: '#000000',
        // strokeWidth: 1,
        fill: '#ffffff'
      }
    }
    const title = new fabric.Textbox(topic, {
      id: 'title',
      fontSize,
      fill: textColor,
      fontFamily: 'Inter',
      left: CANVAS_BASE_WIDTH * (left / 100),
      top: CANVAS_BASE_HEIGHT * (top / 100),
      width: CANVAS_BASE_WIDTH * (width / 100),
      textAlign,
      ...additionalOptions
    });
    canvasRef.current.add(title);
    const expectedHeight = fontSize * 3;
    if (title.height > expectedHeight) {
      title.set({fontSize: Math.floor(fontSize * (expectedHeight / title.height))});
      canvasRef.current.renderAll();
    }
  }

  const addText = async () => {
    if (!template.texts || !template.texts[0]) {
      isRenderedItemsRef.current.text = true;
      finalizeRender();
      return;
    }
    let slideText = [];
    try {
      const response = await designSlides(topic, presentationTopic, 3, sessionToken, user);
      slideText = convertTextToList(response.data.text);
    } catch (error) {
      console.log(error);
      isRenderedItemsRef.current.text = true;
      finalizeRender();
    }

    const {left, top, width, fontSize} = template.texts[0];
    const title = canvasRef.current.getObjects().find((obj) => obj.id === 'title');
    const titleBottom = title.getScaledHeight() + title.top;
    let prevElementBottom = CANVAS_BASE_HEIGHT * (top / 100);
    if (prevElementBottom < titleBottom) {
      prevElementBottom = titleBottom + 20;
    }
    const textElements = [];
    slideText.forEach((text, index) => {
      const currentElementTop = prevElementBottom + 8;
      const textElement = new fabric.Textbox(text, {
        id: 'text-' + uuidv4(),
        fontSize: fontSize || 18,
        fontFamily: 'Inter',
        fill: textColor,
        left: CANVAS_BASE_WIDTH * (left / 100),
        top: currentElementTop,
        width: CANVAS_BASE_WIDTH * (width / 100)
      });
      textElement.set({opacity: 0});
      canvasRef.current.add(textElement);
      textElements.push(textElement);
      prevElementBottom = currentElementTop + textElement.getScaledHeight();
      textElement.animate('opacity', '1', {
        duration: 0,
        onChange: canvasRef.current.renderAll.bind(canvasRef.current),
        onComplete: function () {
          if (index === slideText.length - 1) {
            isRenderedItemsRef.current.text = true;
            finalizeRender();
          }
        }
      });
    });
    if (!textElements.length) {
      return;
    }
    const expectedHeight = (100 - (top + 10)) / 100 * CANVAS_BASE_HEIGHT;
    const lastTextEl = textElements[textElements.length - 1];
    const resultHeight = lastTextEl.top + lastTextEl.getScaledHeight() - textElements[0].top;
    if (resultHeight > expectedHeight) {
      const scale = expectedHeight / resultHeight;
      let prevElementBottom = CANVAS_BASE_HEIGHT * (top / 100);
      if (prevElementBottom < titleBottom) {
        prevElementBottom = titleBottom + 20;
      }
      textElements.forEach((textEl) => {
        const currentElementTop = prevElementBottom + 8;
        textEl.set({fontSize: (fontSize || 18) * scale});
        textEl.top = currentElementTop;
        prevElementBottom = currentElementTop + textEl.getScaledHeight();
      });
      canvasRef.current.renderAll();
    }
  }

  const addImage = (imageId, croppedImageURL, templateParams, photoCredits, isFinal) => {
    addImageToSlide(canvasRef.current, imageId, croppedImageURL, templateParams, photoCredits, () => {
      isRenderedItemsRef.current.image = true;
      isRenderedItemsRef.current.isImageFinal = isFinal;
      finalizeRender();
    });
  }

  const finalizeRender = (isImageFinal) => {
    if (isRenderedItemsRef.current.image) {
      canvasRef.current.renderAll();
      updateSlide(slideId, {
        renderStatus: isRenderedItemsRef.current.isImageFinal ? SLIDE_STATUSES.COMPLETE : SLIDE_STATUSES.CONTENT_GENERATING,
        state: canvasRef.current.toJSON(CANVAS_JSON_PARAMS),
        thumbnail: canvasRef.current.toDataURL({
          format: 'png',
          quality: 1
        }),
        isRendered: true,
        thumbnailSaved: null
      })
    }
  }

  return (
    <div>
      <canvas id={canvasId}></canvas>
      {Boolean(renderImagesData.imagesData.length) && <ImageCrop
        imageTemplates={renderImagesData.templates}
        imagesData={renderImagesData.imagesData}
        onImageCrop={addImage}
      />}
    </div>
  );
};

export default SlidesRendererItem;
