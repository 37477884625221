import * as React from "react";
import {v4 as uuidv4} from 'uuid';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import "./PresentationPage.css";
import {getPresentation} from "../../requests/presentation";
import {usePresentationState} from "./state/presentation";
import Preloader from "../../components/Preloader/Preloader";
import Presentation from "./components/Presentation/Presentation";
import {searchImages} from "../../requests/slides";
import {shuffle} from "../ideas/utils/presentation";
import AuthorizedWrap from "../login/AuthorizedWrap";

const PresentationPage = ({isViewOnly}) => {
  const { presentationId } = useParams();
  const navigate = useNavigate();
  const {setLoadedState, getSlideData, updateSlide, slideIds, topic, resetState} = usePresentationState();
  const [isLoading, setIsLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [slidesUpdated, setSlidesUpdated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sessionToken, setSessionToken] = useState(false);

  useEffect(() => {
    const sessionToken = searchParams.get("sessionToken");
    setSessionToken(sessionToken);
    console.log("presentationId", presentationId, "sessionToken", sessionToken, "searchParams", searchParams);
    if ((presentationId) && (presentationId !== 'new') && !slideIds.length) {
      loadState();
    } else {
      setIsLoading(false);
    }
  }, [presentationId]);

  useEffect(() => {
    if (topic) {
      loadImages();
    }
  }, [topic]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (presentationId === "new") {
      if (imagesLoaded && !slidesUpdated && slideIds.length) {
        provideImagesToSlides();
        setSlidesUpdated(true);
      }
    } else {
      setSlidesUpdated(true);
    }

  }, [imagesLoaded, slideIds]);

  const provideImagesToSlides = () => {
    let imageIndex = 0;
    slideIds.forEach((slideId, index) => {
      const slideData = {...getSlideData(slideId), imageData: []};
      if (window.kesemSearchResults?.length && window.kesemSearchResults[imageIndex]) {
        slideData.template.images.forEach(_image => {
          slideData.imageData.push({
            baseUrl: window.kesemSearchResults[imageIndex].src.medium,
            photoCredits: {
              username: window.kesemSearchResults[imageIndex].photographer,
              url: window.kesemSearchResults[imageIndex].photographer_url
            },
            id: uuidv4(),
            height: window.kesemSearchResults[imageIndex].height,
            width: window.kesemSearchResults[imageIndex].width,
          });
          imageIndex++;
        });
      }

      console.log('update slide');
      updateSlide(slideId, slideData);
    });
  }

  const loadState = () => {
    setIsLoading(true);
    getPresentation(presentationId).then(response => {
      const _data = response.data();
      console.log(_data);
      setLoadedState(_data.topic, _data.slides, _data.thumbnail || _data.slides[0]?.thumbnail);
      setIsLoading(false);
    });
  }

  const loadImages = () => {
    searchImages(topic)
      .then(response => {
        window.kesemSearchResults = shuffle(response.photos);
        setImagesLoaded(true);
      })
      .catch(error => {
        setImagesLoaded(true);
        console.log(error);
      });
  }

  return (
    <div className={'presentation-page'}>
      <Preloader isLoading={isLoading || !imagesLoaded}/>
      {!isLoading && slidesUpdated && <Presentation
        topic={topic}
        presentationId={presentationId}
        sessionToken={sessionToken}
        readOnly={isViewOnly} // Set the readOnly prop to true to disable editing
        onBack={() => {
          navigate('/');
        }}/>}
    </div>
  );
};

const WrappedPresentationPage = (props) => {
  return <AuthorizedWrap>
    <PresentationPage {...props}/>
  </AuthorizedWrap>
}

export default WrappedPresentationPage;
