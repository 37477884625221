import * as React from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {ROUTE} from "../../index";
import { Col } from 'antd';
import './PresentationPreview.css';

const PresentationPreview = ({presentation}) => {

  return (
    <Col className="gutter-row" xs={24} sm={12} md={8} lg={6}>
      <Link to={ROUTE.replace(':presentationId', presentation.id)}>
        <div className={'preview-image-wrap'}>
          <div className={'preview-image'} style={{backgroundImage: `url(${presentation.thumbnail || ''})`}}/>
        </div>
        <div className={'preview-title'}>{presentation.topic}</div>
        <div className={'preview-updated'}>
          Last edited on {moment(presentation.updatedAt).format('MMMM Do, YYYY')} at {moment(presentation.updatedAt).format('h:mm a')}
        </div>
      </Link>
    </Col>
  );
};

export default PresentationPreview;
