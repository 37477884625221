import axios, {CancelToken} from 'axios';
import {v4 as uuidv4} from "uuid";
const axiosApiInstance = axios.create();

const RETRY_ATTEMPTS_COUNT = 3;
const PARALLEL_QUEUE_ITEMS_MAX = 4;
const QUEUE_MAP = {
};

// Add a request interceptor
axiosApiInstance.interceptors.request.use(config => {
  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response;
}, async function (error) {
  const originalRequest = error.config;
  if (!originalRequest) {
    return Promise.reject(error);
  }
  if (originalRequest.allowRetry && (!originalRequest._retryCount || originalRequest._retryCount < RETRY_ATTEMPTS_COUNT)) {
    originalRequest._retryCount = originalRequest._retryCount ? (originalRequest._retryCount + 1) : 1;
    return new Promise(resolve => {
      setTimeout(() => {
        console.log('RETRY', originalRequest._retryCount);
        resolve(axiosApiInstance(originalRequest));
      }, 2000);
    });
  } else {
    return Promise.reject(error);
  }
});

function handleQueue(name, url, data, config) {
  const id = uuidv4();
  const promise = new Promise((resolve, reject) => {
    const queue = QUEUE_MAP[name] ? [...QUEUE_MAP[name]] : [];
    // add item to the queue
    const newItem = {id, resolve, reject, inProgress: false};
    queue.push(newItem);
    QUEUE_MAP[name] = queue;
    if (QUEUE_MAP[name].length <= PARALLEL_QUEUE_ITEMS_MAX) {
      newItem.inProgress = true;
      resolve();
    }
  }).then(() => {
    return axiosApiInstance.post(url, data, config)
  }).then((response) => {
    //cleanup item from queue
    let queue = QUEUE_MAP[name] ? [...QUEUE_MAP[name]] : [];
    queue = queue.filter(_item => _item.id !== id);
    QUEUE_MAP[name] = queue;
    //run next item in line
    const nextItem = queue.find(item => !item.inProgress);
    if (nextItem) {
      nextItem.inProgress = true;
      nextItem.resolve();
    }
    return response;
  });
  return promise;
}

function post(url, data, config) {
  if (config.queueName) {
    return handleQueue(config.queueName, url, data, config);
  }
  return axiosApiInstance.post(url, data, config);
}

export default {post};
