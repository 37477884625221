import * as React from "react";
import {useState, useEffect} from "react";
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import './ProfileMenu.css';
import useLogin from "../../../hooks/useLogin";
import {checkUserSubscription} from "../../../requests/slides";
import {Link, useLocation} from "react-router-dom";
import {ROUTE as LOGIN_ROUTE} from '../../../modules/login';
import {REDIRECT_URI_PARAM} from "../../../modules/login/const";


const ProfileMenu = () => {
  const {handleSignOut, user} = useLogin();
  const location = useLocation();

  const initialItems = [
    {
      key: '1',
      label: 'Settings',
      disabled: true,
    },
    {
      key: '2',
      label: (
        <div className={'sign-out-option'} onClick={handleSignOut}>
          Sign out
        </div>
      ),
    }
  ];
  const [items, setItems] = useState(initialItems);

  const isSubscribed = async () => {
    try{
      const response = await checkUserSubscription();
      return response.data.is_subscribed
    }
    catch (error) {
      return false
    }
  };

  useEffect(() => {
    async function loadSubscription() {
      const isSub = await isSubscribed();
      // console.log("isSub in ProfileMenu", isSub);
      if (isSub) {
        const newItems = initialItems;
        newItems.push({
          key: '3',
          label: (<div className={'sign-out-option'}><a href={"https://billing.stripe.com/p/login/00g4i2grNgHF3T28ww"}>Billing</a></div>),
          disabled: false,
        });
        setItems(newItems);
      }
    }
    loadSubscription();
  }, []);

  if (!user) {
    return <Link to={LOGIN_ROUTE + `?${REDIRECT_URI_PARAM}=${encodeURIComponent(location.pathname)}`}>Log in</Link>
  }

  return (
    <Dropdown menu={{ items }}>
      <div className={'profile-menu-anchor'}>
        {user ? <p style={{padding: "5px"}}>{user.displayName}</p> : ""}
        <Space>
          <div className={'profile-menu-portrait'}>
            <UserOutlined />
          </div>
          <DownOutlined />
        </Space>
      </div>
    </Dropdown>
  );
};

export default ProfileMenu;
