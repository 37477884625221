import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Hero from "../components/hero/TwoColumnWithInput.js";
import Features from "../components/features/ThreeColWithSideImage.js";
import MainFeature from "../components/features/TwoColWithButton.js";
import WaitingList from "../components/forms/TwoColContactUsWithIllustration.js"

import MainFeature2 from "../components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import TrackRecord from "../components/features/TwoColSingleFeatureWithStats2.js"
import FeatureWithSteps from "../components/features/TwoColWithSteps.js";
import Pricing from "../components/pricing/ThreePlans.js";
import Testimonial from "../components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "../components/faqs/SingleCol.js";
import GetStarted from "../components/cta/GetStarted";
import Footer from "../components/footers/FiveColumnWithInputForm";
import heroScreenshotImageSrc from "../images/hero-screenshot-1.png";
import kesemScreenshot from "../images/kesem-screenshot.png"
import prototypeIllustrationImageSrc from "../images/prototype-illustration.svg";
import { ReactComponent as ActivityIcon} from "feather-icons/dist/icons/activity.svg";
import { ReactComponent as UserCheckIcon} from "feather-icons/dist/icons/user-check.svg";
import { ReactComponent as CompassIcon } from "feather-icons/dist/icons/compass.svg";
import ShieldIconImage from "../images/shield-icon.svg";
import SupportIconImage from "../images/support-icon.svg";
import CustomizeIconImage from "../images/customize-icon.svg";
import ReliableIconImage from "../images/reliable-icon.svg";
import FastIconImage from "../images/fast-icon.svg";
import SimpleIconImage from "../images/simple-icon.svg";
import AnalysisIconImage from '../images/analysis.png';

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled={true}>
      <Hero roundedHeaderButton={true} />
      {/*<MainFeature*/}
      {/*  description={"Our platform is built by experts in AI, design, and user experience, ensuring you receive top-quality results every time."}*/}
      {/*  subheading={<Subheading>Expertly Crafted</Subheading>}*/}
      {/*  imageSrc={heroScreenshotImageSrc}*/}
      {/*  imageBorder={true}*/}
      {/*  imageDecoratorBlob={true}*/}
      {/*/>*/}
      <TrackRecord
        description={"Craft meaningful and coherent slideshows with Kesem AI, ensuring your presentations effectively communicate your ideas and resonate with your audience."}
        subheading={"Expertly Crafted"}
        heading={(
            <>
                Storyline That <span tw="text-primary-500">Makes Sense</span>
            </>)
        }
        primaryButtonText={"Learn more"}
        primaryButtonUrl={"/#"}
        statistics={[
            {
                key: "Reduce errors and productivity",
                value: "Up to 80%"
            },
            {
                key: "Increase in engagement",
                value: "Up to 50%"
            },
            {
                key: "Faster slideshow creation",
                value: "Up to 90%"
            }
        ]}
        />
      <div id={"features"}>
          <Features
              cards={[
                  { imageSrc: ShieldIconImage, title: "Automated Storyline", description: "Kesem AI automatically suggests a narrative structure, helping you create. engaging and coherent slideshows tailored to your audience"},
                  { imageSrc: SupportIconImage, title: "Multi-language Functionality",  description: "Craft presentations that resonate with global audiences using our multi-language capabilities"},
                  { imageSrc: FastIconImage, title: "AI-powered Charts", description: "Instruct and prompt Kesem AI to create your own charts. Provide data and leverage our powerful AI engine to create comprehensive and insightful charts." },
                  { imageSrc: AnalysisIconImage, title: "In-depth Analysis", description: "Provide a file or multiple files along with a prompt, and Kesem AI will generate a full analysis. Get questions, charts, insights, and action items based on your data."},
                  { imageSrc: CustomizeIconImage, title: "Visuals Generation", description: "Effortlessly generate and link captivating visuals into your slideshows"},
                  { imageSrc: ReliableIconImage, title: "File Uploads", description: "Easily upload various file formats to Kesem AI. Use your existing content and context to create tailored slideshows" },
                  { imageSrc: SimpleIconImage, title: "Export", description: "Effortlessly export and download your slideshows in various formats. Seamlessly integrate your creations with popular presentation tools"},
              ]}
              description={"Kesem AI transforms slideshow creation, fostering a seamless experience that combines artificial intelligence with user-friendly customization."}
            subheading={<Subheading>Features</Subheading>}
            heading={
              <>
                  Revolutionize Your Slideshow Creation with <HighlightedText>Kesem AI</HighlightedText>
              </>
            }
          />
      </div>
      <FeatureWithSteps
        steps={[
        {
            heading: "Define your core message",
            description: "Make a specific prompt and try as many as you want to tackle different topics."
        },
        {
            heading: "Build your storyline",
            description: "Select the topics related to your slideshow. Define what content your story should have for maximum impact."
        },
        {
            heading: "Make the slideshow your own",
            description: "Tailor writing and style to your needs, with pictures and text according to your preferences. Upgrade a paid member to download your slideshow."
        }]}
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
              Generate your slideshow in <HighlightedText>3 steps</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={kesemScreenshot}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        description={"Our commitment to innovation, quality, and customer satisfaction drives everything we do."}
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        primaryButtonText = ""
        features={[
          {
            Icon: ActivityIcon,
            title: "Innovation",
            description: "We constantly strive to stay at the forefront of AI and design technology, providing you with cutting-edge solutions for your slideshow needs.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: CompassIcon,
            title: "Make the complex simple",
            description: "Bridging the gap between complex data and actionable insights. helping users communicate findings and recommendations more effectively to stakeholders.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          },
          {
            Icon: UserCheckIcon,
            title: "Customer satisfaction",
            description: "Committed to delivering a high-quality service that meets your needs and exceeds your expectations, ensuring your presentations impress every time.",
            iconContainerCss: tw`bg-blue-300 text-blue-800`
          }
        ]}
      />
      <div id={"pricing"}>
          <Pricing
              description={"Choose the subscription plan and start creating your slideshows today."}
                subheading={<Subheading>Pricing</Subheading>}
                heading={
                    <>
                        Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
                    </>
                }
                plans={[
                    {
                        name: "Basic",
                        price: "$9.99",
                        duration: "Monthly (billed annually)",
                        mainFeature: "For individuals",
                        features: ["10 slideshows and 30 slides", "Multi language support",  "Visuals generation (photos)", "Access to Charts & Insights", "Downloadable data", "No watermarks", "Export PDF / PPTX", "Up to 3 files uploaded per query "]
                    },
                    {
                        name: "Professional",
                        price: "$19.99",
                        duration: "Monthly (billed annually)",
                        mainFeature: "For client-facing professionals",
                        features: ["Everything in Basic +", "Unlimited slides and slideshows", "Unlimited Charts & Analysis", "Access to full analysis", "Unlimited files uploaded per query"],
                        featured: true
                    },
                    {
                        name: "Enterprise",
                        price: "Custom",
                        mainFeature: "For large businesses",
                        features: ["Everything in Professional +", "Premium support", "Extended API access", "Dedicated customer success manager", "99.99% SLA"]
                    }
                ]}
            />
      </div>
      {/*<Testimonial*/}
      {/*  description={"Our user-friendly platform and outstanding customer support make Kesem AI a top choice for professionals in various industries."}*/}
      {/*  subheading={<Subheading>Testimonials</Subheading>}*/}
      {/*  heading={*/}
      {/*    <>*/}
      {/*      Our Clients <HighlightedText>Love Us.</HighlightedText>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  testimonials={[*/}
      {/*    {*/}
      {/*      stars: 5,*/}
      {/*      profileImageSrc:*/}
      {/*        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",*/}
      {/*      heading: "Amazing User Experience",*/}
      {/*      quote:*/}
      {/*        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",*/}
      {/*      customerName: "Charlotte Hale",*/}
      {/*      customerTitle: "Director, Delos Inc."*/}
      {/*    },*/}
      {/*    {*/}
      {/*      stars: 5,*/}
      {/*      profileImageSrc:*/}
      {/*        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",*/}
      {/*      heading: "Love the Developer Experience and Design Principles !",*/}
      {/*      quote:*/}
      {/*        "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",*/}
      {/*      customerName: "Adam Cuppy",*/}
      {/*      customerTitle: "Founder, EventsNYC"*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*/>*/}
      {/*  <WaitingList*/}
      {/*      description={"Make your process of creation as seamless as possible by integrating our professional features."}*/}
      {/*      subheading={<Subheading>Join the waiting list</Subheading>}*/}
      {/*      heading={*/}
      {/*          <>*/}
      {/*              Incorporate our tool into your <HighlightedText>professional journey</HighlightedText>*/}
      {/*          </>*/}
      {/*      }*/}
      {/*      submitButtonText={"Join our waiting list"}*/}
      {/*  />*/}
        <div id={"faq"}>
          <FAQ
            description={"And we have got answers to all of them. Explore our platform, features, and pricing options to find the perfect solution for your slideshow creation needs.\n"}
            subheading={<Subheading>FAQS</Subheading>}
            heading={
              <>
                You have <HighlightedText>Questions ?</HighlightedText>
              </>
            }
            faqs={[
              {
                question: "How do I get started with Kesem AI?",
                answer:
                  "To get started, simply sign up. Once registered, you can begin creating your first slideshow by entering your key topic and following the steps provided.",
              },
              {
                question: "How does it work?",
                answer:
                  "Write your key topic you want to build a presentation on then press enter. More detailed ideas on the topic will be presented to you. Pick and choose your ideas - one idea equals more or less one slide, i.e., if you select 8 ideas, you should be getting at least 8 slides. Once you have selected the ideas, press enter, and the slideshow will start building itself up. You can then go viewing it slide by slide; "
              },
              {
                question: "What makes Kesem AI different from other slideshow creators?",
                answer:
                  "Kesem AI utilizes advanced artificial intelligence technology to generate a compelling storyline and tailor your slideshow to your specific topic. Our platform offers a user-friendly experience and a wide range of features, allowing for easy customization and quick creation of professional presentations.",
              },
              {
                  question: "Can I ask anything?",
                  answer:
                      "We inserted a filter just to limit searches which may be harmful to oneself or to others or are considered illegal; otherwise, you can pretty much ask about anything."
              },
              {
                question: "Is my data secure on Kesem AI?",
                answer:
                  "Yes, we take data security very seriously. We follow industry best practices and adhere to SOC 2 Type II compliance standards to ensure your information is protected. Additionally, we offer two-factor authentication for added security. However, since we use GPT technology, we are subject to OpenAI's data limitations when it comes to security and privacy. Please be aware of these limitations and review OpenAI's <a href='https://openai.com/policies/api-data-usage-policies'>Data Usage Policy</a> and <a href='https://www.openai.com/privacy/'>Privacy Policy</a> when using our platform.",
              },
              {
                question: "Are there any limitations to this tool",
                answer:
                  "We are working hard to improve it daily. Right now, it runs on GPT 3.5. Data is only updated up to September 2021, but this issue will be solved soon with the coming release of GPT 4. Additionally, some level of answer might either be too complex or too simple for your expectations, so do take it into account and make the according changes afterward. We will soon be offering different layers of complexities and writing styles depending on your preferences. "
              },
              {
                question: "Can I use my company's personal design?",
                answer:
                  "That's definitely our next step as we have the ultimate goal of creating a one-click solution for slideshow production. Right now, you will have a few options for slideshows famously used on Canva - the most used slideshow design solution in the world. For now, once you are done with the content, you can, as a premium member, download it and incorporate your own design."
              },
              {
                question: "Where do all these great pictures come from?",
                answer:
                  "We have unsplash.com to thank for these unique pictures. Don't hesitate to view the originals on Unsplash by following the links we attach to every presentation or replace these pictures with your own"
              },
            ]}
          />
        </div>
        <div id={"contact"}>
            <GetStarted/>
        </div>
      <Footer />
    </AnimationRevealPage>
  );
}
