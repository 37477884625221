import React, {useEffect, useState} from 'react';
import  useLogin  from '../../hooks/useLogin';
import { Input, Row, Col, Card, Layout } from 'antd';
import './Login.css';
import firebase from 'firebase'
import {COLORS} from "../../const/styles";
import Button from "../../components/Button/Button";
import AppHeader from "../../components/app-header/AppHeader";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {ROUTE as HOME_ROUTE} from '../ideas';
import {REDIRECT_URI_PARAM} from "./const";

const firebaseConfig = {
  apiKey: "AIzaSyBXUJMWfoFvWAy1258wxkA3NpFnA6Yb70w",
  authDomain: "kesemai.firebaseapp.com",
  projectId: "kesemai",
  storageBucket: "kesemai.appspot.com",
  messagingSenderId: "474480292958",
  appId: "1:474480292958:web:893b4678fb857e4945b0cf",
  measurementId: "G-409VG74V7K"
};
// import firebaseConfig from './firebaseConfig';

firebase.initializeApp(firebaseConfig);
const { Header, Content, Footer, Sider } = Layout;

const headerStyle: React.CSSProperties = {
  // textAlign: 'center',
  // align: 'center',
  color: '#fff',
  // lineHeight: '0.2em',
  backgroundColor: '#7dbcea',
  // paddingRight: '10px',
};

function Login(props) {
  const { user, isLoading, handleMicrosoftLogin, handleGoogleLogin, handleEmailLinkLogin,  handleSignOut } = useLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  // Add a useEffect to enable the button after a delay, or when the email is changed
    useEffect(() => {
      if (isButtonDisabled) {
        const timeoutId = setTimeout(() => {
          setButtonDisabled(false);
        }, 6000); // This will re-enable the button after 10 seconds

        return () => clearTimeout(timeoutId); // Clear timeout if the component is unmounted
      }
    }, [isButtonDisabled, email]);

  const handleEmailLogin = async (email) => {
    if (email) {
      setButtonDisabled(true);
      handleEmailLinkLogin(email);
      setEmailSent(true);
    }
  };

  useEffect(() => {
    if (user) {
      const redirectURI = searchParams.get(REDIRECT_URI_PARAM);
      navigate(redirectURI ? decodeURIComponent(redirectURI) : HOME_ROUTE, {replace: true});
    }
  }, [user]);
  if (!user && isLoading) {
    return <div>Loading...</div>;
  }
  if (!user) {
    return (
      <Layout style={{background: COLORS.bgLoginGradient}}>
        <AppHeader isAuthorized={false}></AppHeader>
        <Content className={'login-content'}>
          <Row justify="center" align="middle" className="row-container">
            {/*<Col xs={32} sm={32} md={32} lg={32} xl={32}>*/}
            <Col span={24} md={16} lg={16} className={'card-wrap'}>
              <Card className="card-container">
                <div className="sign-in-container">
                  <p>Please sign in to continue</p>
                    <div className={'login-email-container'}>
                      <Input
                          size="large"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email address"
                      />
                      <Button isDisabled={isButtonDisabled} onClick={() => handleEmailLogin(email)}
                              text={'Sign in with email link'}
                      />
                      {emailSent && <p>Check your email for the sign-in link!</p>}
                    </div>
                  <div className={'login-or-container'}>OR</div>
                  <div className={'social-buttons-wrap'}>
                    <Button onClick={handleGoogleLogin}
                            text={'Sign in with Google'}
                            iconSrc={'/images/icons/google.svg'}
                    />
                    <Button onClick={handleMicrosoftLogin}
                            text={'Sign in with Microsoft'}
                            iconSrc={'/images/icons/msft.svg'}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
  if (user) {
    return props.children;
  }
}

export default Login;
