import axios from "axios";
import http from "../utils/http";
import { FASTAPI_URL} from "../const/api";
import { createApi } from "unsplash-js";
import {dataURItoBlob} from "../utils/file";
import firebase from 'firebase';

const unsplashApi = createApi({
  // See https://unsplash.com/developers
  apiUrl: `${FASTAPI_URL}`
});

// Set up Axios instance (so can respect cookies)
const api = axios.create({
    withCredentials: true,
});

//
// export function designSlides(slideTopic, slideshowTopic, pointsQty, sessionToken) {
//   // let token = user.getIdToken();
//   let token = 'bla';
//   return api.post(`${API_URL}/design-slides`,
//     {
//       slideTopic,
//       topic: slideshowTopic,
//       pointsQty,
//       token: token,
//       sessionToken: sessionToken
//     }, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     });
// }

export async function designSlides(slideTopic, slideshowTopic, pointsQty, sessionToken, user, files) {
  let token = await firebase.auth().currentUser.getIdToken();

  return http.post(`${FASTAPI_URL}/design-slides/`,
    {
      slideTopic,
      topic: slideshowTopic,
      pointsQty,
      token: token,
      sessionToken: sessionToken,
      files: files
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
        allowRetry: true,
        // queueName: 'TEXT_GENERATION'
    });
}

export async function fetchImage(topic, slideTopic) {
    let token = await firebase.auth().currentUser.getIdToken();

    const response = await axios.post(`${FASTAPI_URL}/image/`, {
        topic: topic,
        slideTopic: slideTopic,
        token: token,
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export async function searchImages(topic) {
    let token = await firebase.auth().currentUser.getIdToken();
    try {
        const response = await axios.get(`${FASTAPI_URL}/search/photos`, {
            params: {
                query: topic,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.log("something went wrong!", error);
    }
}

export async function submitSlidesTopicFiles(topic, files) {
    let token = await firebase.auth().currentUser.getIdToken();

    console.log("in SubmitSlidesTopicFiles", topic, files);

    const apiUrl = `${FASTAPI_URL}/slideshows/`;
    let dataToSend = {
        files: files,
        topic: topic,
    };
    let headers = {
        'Authorization': `Bearer ${token}`
    };

    return axios.post(apiUrl, dataToSend, {
        headers: headers
    });
}

export async function uploadImage(imageB64, filename=null) {
    let token = await firebase.auth().currentUser.getIdToken();

    const blob = dataURItoBlob(imageB64);
    const formData = new FormData();
    formData.append('file', blob);

    if (filename){
        formData.append('filename', filename);
    }

    return http.post(`${FASTAPI_URL}/upload/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        },
        allowRetry: true,
        queueName: 'UPLOAD'
    })
}

export async function checkUserSubscription(){
    let token = await firebase.auth().currentUser.getIdToken();
    return api.post(`${FASTAPI_URL}/subscription/`,
        {}, {
        headers: {
        'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    });
};
