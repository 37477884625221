import * as React from "react";
import { Row, Col, Layout } from 'antd';
import {HomePage} from "../ideas";
import {PresentationPage, ROUTE as PRESENTATION_ROUTE} from "../presentation";
import './Main.css';
import {COLORS} from "../../const/styles";
import AppHeader from "../../components/app-header/AppHeader";
import {
  Routes,
  Route,
} from "react-router-dom";
import {ROUTE as LOGIN_ROUTE, LoginPage} from "../login";
import Charts from "../charts/Charts";
import Analysis from "../analysis/Analysis";
import {PRIVACY_ROUTE, TERMS_ROUTE} from "../landing";
import {PrivacyPage, TermsPage} from "../landing/LandingPage";
const { Content } = Layout;

const Main = () => {
  return (
        <Routes>
          <Route path={LOGIN_ROUTE} element={<LoginPage/>}/>
          <Route path="/shared/:presentationId" element={<PublicRoutes />} />
            <Route path={PRIVACY_ROUTE} element={<PrivacyPage />} />
            <Route path={TERMS_ROUTE} element={<TermsPage />} />
            <Route path="/*" element={<ProtectedRoutes />} />
        </Routes>
  );
};


const ProtectedRoutes = () => (
      <Layout className={'layout-main'} style={{background: COLORS.bgMain}}>
        <AppHeader isAuthorized={true}/>
          <Row justify="center" align="middle" className="row-container">
            <Col span={24}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path={PRESENTATION_ROUTE} element={<PresentationPage />} />
                <Route path="/charts/:sessionId?" element={<Charts />} />
                <Route path="/analysis" element={<Analysis/>}/>
              </Routes>
            </Col>
          </Row>
      </Layout>
);

const PublicRoutes = () => (
    <Layout className={'layout-main'} style={{background: COLORS.bgMain}}>
      <AppHeader isAuthorized={false} readOnly={true}/>
      <Content>
        <Row justify="center" align="middle" className="row-container">
          <Col span={24}>
            <PresentationPage isViewOnly={true}/>
          </Col>
        </Row>
      </Content>
    </Layout>
);

export default Main;
