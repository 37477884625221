import React, {useEffect, useState, useRef} from "react";

import styled from "styled-components";
import { ReactComponent as VolumeX  } from "feather-icons/dist/icons/volume-x.svg";
import { ReactComponent as Volume2  } from "feather-icons/dist/icons/volume-2.svg";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images//svg-decorator-blob-1.svg";
import DesignIllustration from "../../images//design-illustration-2.svg";
// import DesignIllustration from "../../images/design-kesem-3.png";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import {PrimaryLink} from "../headers/light";
import { Link } from 'react-router-dom';
import {FASTAPI_URL} from "../../../../const/api";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;


const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  button {
    ${tw`
    w-full right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-64 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300 `}
  }
`;

// input {
//   ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
// }

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-4 lg:mt-4`}
  p {
    ${tw`mt-4 uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-800`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({roundedHeaderButton}) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [numDocs, setNumDocs] = useState({"slideshows": 10000, "charts": 5000, "analysis": 3000});

  useEffect(() => {
    async function fetchNumSlideshows() {
      const res = await fetch(`${FASTAPI_URL}/slideshows/count`);
      const data = await res.json();
      setNumDocs(data.num_docs);
    }
    fetchNumSlideshows();
  }, []);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              {/*Create a professional presentation*/}
              Create slideshows, charts, and analyses content faster than ever <span tw="text-primary-500">using AI.</span>
            </Heading>
            <Paragraph>
              Bring your ideas to life through an intuitive interface designed for crafting storylines,
              generating insightful charts, and providing in-depth analysis perfectly suited to your needs.
            </Paragraph>
            <Actions>
              <button><Link to="/">Start creating now</Link></button>
              {/*<input type="text" placeholder="Your E-mail Address" />*/}
            </Actions>
            <CustomersLogoStrip>
              <p>{numDocs?.slideshows && numDocs?.charts && numDocs?.analysis ? `${numDocs.slideshows.toLocaleString()} Slideshows, ${numDocs.charts.toLocaleString()} 
              Charts, and ${numDocs.analysis.toLocaleString()} Analyses were already created on KESEM.AI` : "Loading..."}</p>
              {/*<img src={CustomersLogoStripImage} alt="Our Customers" /> */}
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
          <IllustrationContainer>
            <video
              ref={videoRef}
              tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
              src="https://kesem-public.s3.amazonaws.com/demo.mp4"
              autoPlay
              loop
              playsInline
              muted={isMuted}
            />
          </IllustrationContainer>
          <button tw="mt-2" onClick={toggleMute}>
            {isMuted ? <VolumeX /> : <Volume2 />}
          </button>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
