import * as React from "react";
import { message } from 'antd';
import IdeasForm from "./components/IdeasForm/IdeasForm";
import {useEffect, useState} from "react";
import {submitSlidesTopicFiles} from "../../requests/slides";
import IdeasList from "./components/IdeasList/IdeasList";
import Preloader from "../../components/Preloader/Preloader";
import {convertTextToList} from "./utils/presentation";
import useLogin from "../../hooks/useLogin";
import {getSavedPresentations} from "../../requests/presentation";
import {usePresentationState} from "../presentation/state/presentation";
import {useNavigate, createSearchParams} from "react-router-dom";
import {ROUTE} from "../presentation";
import PresentationsList from "./components/PresentationsList/PresentationsList";
import axios from "axios";
import {getUserFiles} from "../../requests/analysis";
import ReactGA from "react-ga4";
import { isDev } from "../../utils/env";
import {FASTAPI_URL} from "../../const/api";
import AuthorizedWrap from "../login/AuthorizedWrap";

const Home = () => {
  const {setNewSlides} = usePresentationState();
  const navigate = useNavigate();
  const [topicItems, setTopicItems] = useState([]);
  const [topic, setTopic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [slideshowList, setSlideshowList] = useState([]);
  const [slideshow, setSlideshow] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [token, setToken] = React.useState([]);
  const { user } = useLogin();

  useEffect(() => {
    if (user) {
      loadPresentations();
    }
  }, [user]);

  const loadPresentations = async () => {
    try {
      const querySnapshot = await getSavedPresentations(user.uid);
      const data = querySnapshot.docs.map((doc) => {
        // console.log(doc.data());
        const _data = doc.data();
        return {
          id: doc.id,
          slides: _data.slides,
          updatedAt: _data.updatedAt?.toDate(),
          topic: _data.topic,
          thumbnail: _data.thumbnail || _data.slides[0].thumbnail
        }
      }).filter(x => x.topic.length !== 0);

      setSlideshowList(data);
    } catch(error) {
      console.log("Error getting documents: ", error);
    }
  }

  const handleTopicSubmit = async (_topic, files) => {
    setIsLoading(true);
    try{
      const response = await submitSlidesTopicFiles(_topic, files);
      setTopicItems(convertTextToList(response.data.text));
      setTopic(response.data.topic);
      setSessionToken(response.data.session_token);
      setIsLoading(false);
      if (!isDev) {
        ReactGA.event({
          category: "User Action",
          action: "Topic submitted",
          label: "Presentation Creation Started",
        });
      }
    }
    catch (error){
      setIsLoading(false);
      if (error.response && error.response.status === 413) {
        message.error('One of the files has more than 2000 words or is too large. Please try again with shorter files.');
      }
      else{
        throw error;
      }
    }
  };
  const handleTopicItemsSubmit = (_selectedItems) => {
    if (!isDev) {
      ReactGA.event({
        category: "User Action",
        action: "Presentation Submit",
        label: "Presentation Created",
      });
    }

    // setSelectedTopicItems(_selectedItems);
    setNewSlides(topic, _selectedItems);
    setTimeout(() => {
      sessionToken ? navigate({pathname:
                ROUTE.replace(':presentationId', 'new'),
            search: createSearchParams({sessionToken: sessionToken}).toString()
      })
          : navigate(ROUTE.replace(':presentationId', 'new'), );
    }, 100);
  }

  const handleGenerateMoreTopicItems = async () => {
    try{
      setIsLoading(true);
      const response = await submitSlidesTopicFiles(topic, {}, user);
      setTopicItems([...topicItems, ...convertTextToList(response.data.text)]);
      setIsLoading(false);
    }
    catch (error){
      setIsLoading(false);
      throw error;
    }
  }

  React.useEffect(() => {
    const fetchToken = async () => {
      const token = await user.getIdToken();
      setToken(token);
    };
    if (user) {
      fetchToken();
    }
  }, [user]);

  const fetchUserFiles = async () => {
    const data = await getUserFiles();
    console.log(`response data ${JSON.stringify(data)}`);
    setUserFiles(data);
  };

  React.useEffect(() => {
    if (user && token) {
      fetchUserFiles();
    }
  }, [user, token]);

  const updateUserFiles = (uploadedFile, status, percent=100) => {
    const updatedFile = {
      name: uploadedFile,
      status: status,
      percent: percent,
    };

    if (status === "done"){
      setUserFiles((prevState) => [...prevState, updatedFile.name]);
      setUploadedFiles((prevState) => [...prevState, updatedFile.name]);
    }
  };

  const customRequest = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios({
        url: `${FASTAPI_URL}/user/upload/`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          updateUserFiles(file.name, 'uploading', percentCompleted);
          onProgress({ percent: percentCompleted }, file);
        },
      });

      if (response.status === 200) {
        onSuccess(response.data, file);
        message.success(`${file.name} file uploaded successfully`);
        updateUserFiles(file.name, 'done');
      } else {
        onError(new Error('Upload failed.'));
        message.error(`${file.name} file upload failed.`);
        updateUserFiles(file.name, 'error');
      }
    } catch (error) {
      onError(error);
      message.error(`${file.name} file upload failed.`);
      updateUserFiles(file.name, 'error');
    }
  };

  const uploadProps = {
    name: "file",
    openFileDialogOnClick: true,
    action: `${FASTAPI_URL}/user/upload/`,
    multiple: true,
    accept: [".csv",".pdf",".txt",".doc",".docx"],
    showUploadList: false,
    // showUploadList: {
    //     showRemoveIcon: true, // Hide remove icon in the file list
    // },
    // fileList: selectedFiles,
    customRequest: customRequest,
  };


  const renderContent = () => {
    // return <Presentation topic={'Cats'} selectedItems={['Cute cats', 'Domestic cats', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt']}/>
    if (!topicItems.length && !slideshow) {
      return(
        <div>
            <IdeasForm
                topicItems={topicItems}
                onTopicSubmit={handleTopicSubmit}
                fileNames={userFiles.concat(uploadedFiles)}
                uploadedfFiles={uploadedFiles}
                uploadProps={uploadProps}
                initialSelectedFiles={[]}
          />
          <PresentationsList slideshowList={slideshowList}/>
        </div>
      );
    }

    return <IdeasList
      topic={topic}
      topicItems={topicItems}
      onTopicItemsSubmit={handleTopicItemsSubmit}
      onGenerateMoreTopicItems={handleGenerateMoreTopicItems}
    />;
  }

  return (
    <>
      {renderContent()}
      <Preloader isLoading={isLoading}/>
    </>
  )

};

const WrappedHome = (props) => {
  return <AuthorizedWrap>
    <Home {...props}/>
  </AuthorizedWrap>
}

export default WrappedHome;
