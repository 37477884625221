import * as React from "react";
import {CANVAS_BASE_HEIGHT, CANVAS_BASE_WIDTH} from "../../../../const/canvas";
import ImageCropItem from "./ImageCropItem";
import {useEffect, useState} from "react";
import {uploadImage} from "../../../../requests/slides";
import {FASTAPI_URL} from "../../../../const/api";

const ImageCrop = ({imageTemplates, imagesData, onImageCrop, imageRatio}) => {
  const [renderData, setRenderData] = useState({
    imageTemplate: null,
    imageData: null
  });
  useEffect(() => {
    setRenderData({
      imageTemplate: imageTemplates[0],
      imageData: imagesData[0]
    })
  }, []);

  const handleImageCrop = (croppedImage) => {
    onImageCrop(
      renderData.imageData.id,
      croppedImage,
      renderData.imageTemplate,
      renderData.imageData.photoCredits,
      false
    )
    uploadImage(croppedImage).then(response => {
      console.log('IMAGE UPLOADED', response);
      if (response.data?.file) {
        onImageCrop(
          renderData.imageData.id,
          `${FASTAPI_URL}${response.data.file}`,
          renderData.imageTemplate,
          renderData.imageData.photoCredits,
          true
        );
      } else {
        console.error('Could not get cropped image,', response);
      }
    });

    const renderedItemIndex = imagesData.findIndex(_data => _data.id === renderData.imageData.id);
    if (imagesData.length > (renderedItemIndex + 1)) {
      setRenderData({
        imageTemplate: imageTemplates[renderedItemIndex + 1],
        imageData: imagesData[renderedItemIndex + 1]
      })
    }
  }
  if (!renderData.imageData) {
    return null;
  }
  const {imageData, imageTemplate} = renderData;
  return (
    <ImageCropItem
      imageOriginData={imageData}
      onImageCrop={handleImageCrop}
      imageHeightToWidthRatio={imageRatio ? imageRatio : (CANVAS_BASE_HEIGHT * (imageTemplate.height / 100)) / (CANVAS_BASE_WIDTH * (imageTemplate.width / 100))}/>
  );
};

export default ImageCrop;
