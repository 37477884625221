import firebase from "firebase";
import {v4 as uuidv4} from "uuid";
import {SLIDE_STATUSES} from "../const/slide";

export async function getSavedPresentations(userId) {
  return firebase.firestore().collection("slideshows")
    .where("user", "==", userId)
    .orderBy("updatedAt", "desc")
    .get();
}

export async function getPresentation(presentationId) {
  return firebase.firestore().collection("slideshows").doc(presentationId).get();
}

export async function savePresentation(presentationId, presentationThumbnail, slides, topic, user, sessionToken) {
  return firebase.firestore().collection("slideshows").doc(presentationId)
    .set({
      slides: slides.map(slide => {
        return {
          ...slide,
          renderStatus: null,
          thumbnail: null
        };
      }),
      thumbnail: presentationThumbnail,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      user: user.uid,
      topic: topic
    }, {merge: true})
    .then(() => {
      console.log("Document written");
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
}

export const updatePresentationSharedStatus = (presentationId, isShared) => {
    // updated the flag isShared on the slideshow document
    return firebase.firestore().collection("slideshows").doc(presentationId).update({ isShared: isShared });
};
